import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogBaseComponent } from 'src/app/components/common/dialog/dialog-base/dialog-base.component';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { ReportsMenuService } from '../reports-menu.service';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { Router } from '@angular/router';
import { AppConstatnts } from 'src/app/common/constants/app-contstants';

@Component({
  selector: 'app-variations',
  templateUrl: './variations.component.html',
  styleUrls: ['./variations.component.scss']
})
export class VariationsComponent  extends DialogBaseComponent implements OnInit  {
  
  @Input() data;
  @Output() onCompleted = new EventEmitter<any>();

  report: any;
  selectedContainer: HTMLElement | null = null;
  selectedvariationId: number;
  public isAddButtonActive: boolean;

  constructor(private ds: DialogService,
    private ref1: ChangeDetectorRef, readonly reportsMenuService: ReportsMenuService, private translateHandler: TranslateHandler, readonly router: Router) {
    super(ds, ref1);
  }
  
  ngOnInit(): void {
    if (this.data.reportInfo?.variations) {
      const variations = this.data.reportInfo.variations;
      this.data.reportInfo.variations = variations.sort((a: any, b: any) => {
        const isNewA = this.isNewReport(a.createdDate);
        const isNewB = this.isNewReport(b.createdDate);
        if (isNewA && !isNewB) {
          return -1;
        } else if (!isNewA && isNewB) {
          return 1;
        } else {
          const suffixA = this.getSuffix(a.name).substring(0, 3).toLowerCase();
          const suffixB = this.getSuffix(b.name).substring(0, 3).toLowerCase();
          return suffixA.localeCompare(suffixB);
        }
      });
      this.report = this.data.reportInfo; 
    } 
  }
  
  isNewReport(createdDate: string): boolean {
    const currentDate = new Date();
    const reportDate = new Date(createdDate);
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);
    return reportDate >= oneMonthAgo;
  }
  
  getSuffix(name: string): string {
    const parts = this.translateHandler.translate(name).split(' - ');
    return parts.length > 1 ? parts[1] : '';
  }

  onReportSelect(container: HTMLElement, reportId: any) {
    const isSameReportSelected = this.selectedvariationId === reportId;
    this.selectedContainer?.classList.remove('v-selected');
    if (isSameReportSelected) {
      this.clearSelection();
    } else {
      container.classList.add('v-selected');
      this.selectedvariationId = reportId;
      this.selectedContainer = container;
      this.isAddButtonActive = true;
    }
  }

  clearSelection(): void {
    this.clearAllRadioButtons();
    this.selectedvariationId = null;
    this.selectedContainer = null;
    this.isAddButtonActive = false;
  }

  clearAllRadioButtons(): void {
    const radioButtons = document.querySelectorAll('.variation-radio') as NodeListOf<HTMLInputElement>;
    radioButtons.forEach((radioButton) => (radioButton.checked = false));
  }

  loadReport() {
    if (this.selectedvariationId == null) return;
    const variation = this.report.variations.find((variation) => variation.id === this.selectedvariationId);
    this.close(); // close variations popup
    this.close(); // close reports popup
    this.router.navigate([AppConstatnts.routerModes[variation.menuName]]);
  }

  cancel() {
    this.close();
  }
}
