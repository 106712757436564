<div class="maindiv">
  <div class="mainreport-toolbar-container">
    <div class="mainreport-toolbar">
      <!-- <button [ngClass]="true ? 'tb-btn-enable' : 'tb-btn-disable'" class="tb-btn" (click)="reset()">
        <img src="/assets/images/icons/bookreport-regenerate.svg" alt="Reset-Button">
        <span class="toolTip">{{"lbl_reset" | translate}}</span>
      </button> -->

      <button [disabled]="false" [ngClass]="true ? 'tb-btn-enable' : 'tb-btn-disable'" class="tb-btn"
        (click)="zoom(true)">
        <img src="/assets/images/icons/zoom-in.svg" alt="ZoomIn-Button">
        <span class="toolTip">{{"lbl_zoomin" | translate}}</span>
      </button>

      <button [disabled]="false" [ngClass]="true ? 'tb-btn-enable' : 'tb-btn-disable'" class="tb-btn"
        (click)="zoom(false)">
        <img src="/assets/images/icons/zoom-out.svg" alt="ZoomOut-Button">
        <span class="toolTip">{{"lbl_zoomout" | translate}}</span>
      </button>

      <button [disabled]="false" [ngClass]="true ? 'tb-btn-enable' : 'tb-btn-disable'" class="tb-btn"
        (click)="isCenterEnabled ? center() : null">
        <img src="/assets/images/icons/center.svg" alt="Center-Button">
        <span class="toolTip">{{"lbl_center" | translate}}</span>
      </button>

      <button [disabled]="false" [ngClass]="true ? 'tb-btn-enable' : 'tb-btn-disable'" class="tb-btn"
        (click)="zoomToExtend()">
        <img src="/assets/images/icons/zoomextent.svg" alt="ZoomExt-Button">
        <span class="toolTip">{{"lbl_zoomToExtend" | translate}}</span>
      </button>

      <button [disabled]="themeContentlist?.length<=0"
        [ngClass]="themeContentlist?.length>0 ? 'tb-btn-themes' : 'tb-btn-disable'" class="tb-btn">
        <img src="/assets/images/icons/themes.svg" alt="Themes-Button">
        <div class="dropdown-content">
          <div *ngFor="let theme of themeContentlist" (click)="selectTheme(theme.id)">
            <span>{{theme.name| translate}}</span>
          </div>
        </div>
      </button>

      <button [disabled]="false" [ngClass]="true ? 'tb-btn-enable' : 'tb-btn-disable'" class="tb-btn"
        (click)="publishReport()">
        <img src="/assets/images/icons/download.svg" alt="Publish-Button">
        <span class="toolTip">{{"lbl_publish" | translate}}</span>
      </button>

      <button [disabled]="false" [ngClass]="true ? 'tb-btn-enable' : 'tb-btn-disable'" class="tb-btn"
        (click)="openReportPopup()">
        <img src="/assets/images/icons/settings.svg" alt="Settings-Button">
        <span class="toolTip">{{"lbl_options" | translate}}</span>
      </button>

    </div>
  </div>

  <div *ngIf="zoomlevel" class="zoomlevel">zoom level:{{ currentZoomLevel }}%</div>
  <div id="domReport">

    <ng-template appDynamicReport></ng-template>

  </div>
</div>