<div class="ft-form">

    <div class="content">
        <!-- form title -->
        <form class="form" id="ngFormPlace" #f="ngForm" (ngSubmit)="f.form.valid && addPlace()">
            <div class="ft-form-row">
            <span class="title">{{data.title}}</span>
            </div>
            <div class="ft-form-row">
            <span >{{"lbl_place_show" | translate}} {{getFullAddress()}}</span>
            </div>

            <div class="ft-form-row col2-vf">
                <div class="border-outline">
                    <!-- Family images carousel -->
                    <app-image-carousel-temp 
                        [inImages]="placeModel.multimedias" 
                        [defaultImage]="defaultCarouselImage"
                        (action)="carouselActionClicked($event)">
                    </app-image-carousel-temp>
                </div>
                <div>
                    <div class="ft-form-row col1-v100 place-type">
                        <div class="ft-form-input">
                            <label>{{"lbl_place_type" | translate}}</label>
                            <select [(ngModel)]="selectedPlaceType" [ngModelOptions]="{standalone: true}"  (ngModelChange)="onModelChange()">
                                <option *ngFor="let place of placeTypeList" [ngValue]="place">
                                    {{place.description  | translate}}</option>

                            </select>
                        </div>
                    </div>
                    <div class="ft-form-row col2-v50v50 place-name">
                        <div class="ft-form-input">
                            <label>{{selectedPlaceType.fields.place | translate}} <span class="validation-message">*</span></label>
                            <input type="text" [(ngModel)]="placeModel.detail" #detail="ngModel" name="detail" 
                             [required]="(placeModel.detail)"
                             (ngModelChange)="addressChanged(true)">
                            <div *ngIf="detail.invalid" class="form-control-feedback">
                                    <p class="validation-message">
                                        {{"val_place_validation_message" | translate}}</p>
                            </div>
                        </div>
                        <div class="ft-form-input">
                            <label>{{ selectedPlaceType.fields.farm | translate}}</label>
                            <input type="text" [(ngModel)]="placeModel.line2" #line2="ngModel" name="line2"
                            (ngModelChange)="addressChanged()">
                        </div>
                    </div>
                </div>
            </div>

            <div class="ft-form-row col2-v50v50">
                <div class="ft-form-input">
                    <label>{{selectedPlaceType.fields.village | translate}}</label>
                    <input type="text" [(ngModel)]="placeModel.line3" #line3="ngModel" name="line3"
                    (ngModelChange)="addressChanged()">
                </div>
                <div class="ft-form-input">
                    <label>{{selectedPlaceType.fields.municipility | translate}}</label>
                    <input type="text" [(ngModel)]="placeModel.line4" #line4="ngModel" name="line4"
                    (ngModelChange)="addressChanged()">
                </div>
            </div>
            <div class="ft-form-row col2-v50v50">
                <div class="ft-form-input">
                    <label>{{selectedPlaceType.fields.county | translate}}</label>
                    <input type="text" [(ngModel)]="placeModel.line5" #line5="ngModel" name="line5"
                    (ngModelChange)="addressChanged()">
                </div>
                <div class="ft-form-input">
                    <label>{{selectedPlaceType.fields.country | translate}}</label>
                    <input type="text" [(ngModel)]="placeModel.country" #country="ngModel" name="country"
                    (ngModelChange)="addressChanged()">
                </div>
            </div>
            <div class="ft-form-row col2-v50v50">              
                <div class="ft-form-input">
                    <label>{{selectedPlaceType.fields.latitude | translate}}</label>
                    <input type="number" [(ngModel)]="placeModel.lat" #lat="ngModel" name="lat" 
                    (ngModelChange)="validateForm()">
                      <div *ngIf="!isValidLat">
                        <p class="validation-message">{{"place.geocode.val_invalid_lat" | translate}}</p>
                      </div>
                      <div *ngIf="showGeocodeGenerateError">
                        <p class="input-s-warning">{{"place.geocode.val_geocode_not_found" | translate}}</p>
                      </div>
                      <div *ngIf="showAddressNotifier">
                        <p class="input-s-warning">{{"place.geocode.val_address" | translate}} {{geocodeAddress}}</p>
                      </div>
                </div>                 
                  
                <div class="ft-form-input">
                    <label>{{selectedPlaceType.fields.longitude | translate}}</label>
                    <input type="number" [(ngModel)]="placeModel.lng" #lng="ngModel" name="lng" 
                    (ngModelChange)="validateForm()">
                      <div *ngIf="!isValidLng">
                        <p class="validation-message">{{"place.geocode.val_invalid_lng" | translate}}</p>
                      </div>
                </div> 
                <div class="geocode-btn-container">
                    <img src="/assets/images/icons/place-editor-open-google-map.svg" alt="OpenGoogleMapButton"
                         class="ft-btn-round-green-base ft-btn-round-green-base-style"
                         (click)="openGoogleMap(true)"
                         [ngClass]="{'disabled ft-btn-round-green-base-inactive': !placeModel.detail}">
                    <div class="ft-tooltip">{{"place.geocode.lbl_open_google_map"| translate}}</div>
                </div>
                <div class="geocode-btn-container">      
                    <img src="/assets/images/icons/place-editor-paste-geocode.svg" alt="PasteGeocodeButton"
                         class="ft-btn-round-green-base ft-btn-round-green-base-style"
                         (click)="pasteGeocode()"
                         [ngClass]="{'disabled ft-btn-round-green-base-inactive': !placeModel.detail}">
                    <div class="ft-tooltip">{{"place.geocode.lbl_paste_geocode"| translate}}</div> 
                </div> 
                <!-- Disabled in production -->
                     <!-- <div class="geocode-btn-container">
                    <img src="/assets/images/icons/place-editor-generate-geocodes.svg" alt="GenerateGeocodeButton"
                         class="ft-btn-round-green-base ft-btn-round-green-base-style"
                         (click)="processGeocodeData()"
                         [ngClass]="{'disabled ft-btn-round-green-base-inactive': !placeModel.detail}">
                    <div class="ft-tooltip">{{"place.geocode.lbl_generate_geocode"| translate}}</div>
                </div> -->
                <div class="geocode-btn-container">      
                    <img src="/assets/images/icons/place-editor-check-location.svg" alt="CheckLocationButton"
                         class="ft-btn-round-green-base ft-btn-round-green-base-style"
                         (click)="openGoogleMap(false)"
                         [ngClass]="{'disabled ft-btn-round-green-base-inactive':isInvalidLocation}">
                    <div class="ft-tooltip">{{"place.geocode.lbl_check_location"| translate}}</div> 
                </div>
            </div>
            <div class="ft-form-row col1-v100">
                <div class="ft-form-input">
                    <label>{{"lbl_note" | translate}}</label>
                    <input type="text" [(ngModel)]="placeModel.note" #note="ngModel" name="note" (keydown)="isDirty=true">
                </div>
            </div>
            
        </form>
        <div  class="ft-form-row col1-v100" style="height: 35%; margin-top: 25px;">
            <app-event-list style="width: 100%;" [eventList]="eventList" [data]="data" [eventConfig]="eventConfig"></app-event-list>
        </div>
    </div>
    <div class="footer">
        <button class="ft-btn" type="submit" form="ngFormPlace"
            [ngClass]="isAddButtonActive && isDirty ? 'ft-btn-active' : 'ft-btn-inactive'"
            [disabled]="!(isAddButtonActive && isDirty)">
            {{"lbl_add" | translate}}
        </button>
        <button type="button" class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
    </div>

</div>