<link rel="stylesheet" [href]="commonUrl + 'themes/common.css' | safe" />

<svg id="svgViewableArea" preserveAspectRatio="xMidYMid meet"
  [attr.viewBox]="reportTranslationObj.viewBoxReportAttribute" 
  [attr.height]="config.reportArea.h"
  [attr.width]="config.reportArea.w" 
  class="svgfull reportCommon" *ngIf="showReport">

  <g [style.visibility]="reportTranslationObj.hide ? 'hidden' : 'visible'" 
     id="reportArea"  
     [attr.transform]="reportTranslationObj.translateReportAttribute">

    <text 
     [attr.x]="config.base.reportTitle.x" 
     [attr.y]="config.base.reportTitle.y"
     [attr.font-size]="config.base.reportTitle.size" 
     [attr.class]="config.base.reportTitle.style" 
     text-anchor="middle">
     {{config.options.title}}
   </text>

    <image 
      [attr.href]="commonUrl + 'images/Main_Circle.svg'" 
      [attr.x]="config.base.mainCircle.x"
      [attr.y]="config.base.mainCircle.y" 
      [attr.height]="config.base.mainCircle.height"
      [attr.width]="config.base.mainCircle.width" />

    <text fill="white" text-anchor="middle">
      <tspan [attr.x]="config.base.mainCircle.text1x" [attr.y]="config.base.mainCircle.text1y"
        [attr.font-size]="config.base.mainCircle.text1size" class="svgtext">{{mainCircleText}}</tspan>
      <tspan [attr.x]="config.base.mainCircle.text2x" [attr.y]="config.base.mainCircle.text2y"
        [attr.font-size]="config.base.mainCircle.text2size" class="svgtext">{{totalFamilyMembers}}</tspan>
    </text>

    <g *ngFor="let position of circlePositions">

      <image 
        [attr.x]="position.lineStartX - config.base.centerLine.offsetX" 
        [attr.y]="position.lineStartY - config.base.centerLine.offsetY" 
        [attr.width]="config.base.centerLine.width"
        [attr.height]="config.base.centerLine.height" 
        [attr.href]="commonUrl + 'images/Center_Line.svg'"
        [attr.transform]="'rotate(' + position.lineAngle + ',' + position.lineStartX + ',' + position.lineStartY + ')'" />

      <image 
        [attr.x]="(position.cx - config.base.secondaryCircle.height/2)" 
        [attr.y]="(position.cy - config.base.secondaryCircle.height/2)" 
        [attr.height]="config.base.secondaryCircle.height"
        [attr.width]="config.base.secondaryCircle.width" 
        [attr.href]="commonUrl + 'images/Sub_Circle.svg'" />

      <text 
        fill="white" 
        [attr.font-size]="config.base.secondaryCircle.fontsize"
        class="svgtext"
        text-anchor="middle" 
        alignment-baseline="middle">

        <tspan *ngIf="position.label.length === 1" [attr.x]="position.cx" [attr.y]="position.cy + 10">{{ position.label[0] }}</tspan>

        <tspan *ngIf="position.label.length === 2" [attr.x]="position.cx" [attr.y]="position.cy - 30">{{ position.label[0] }}</tspan>
        <tspan *ngIf="position.label.length === 2" [attr.x]="position.cx" [attr.y]="position.cy">{{ position.label[1] }}</tspan>

        <tspan *ngIf="position.label.length === 3" [attr.x]="position.cx" [attr.y]="position.cy - 20">{{ position.label[0] }}</tspan>
        <tspan *ngIf="position.label.length === 3" [attr.x]="position.cx" [attr.y]="position.cy + 10" >{{ position.label[1] }}</tspan>
        <tspan *ngIf="position.label.length === 3" [attr.x]="position.cx" [attr.y]="position.cy + 40">{{ position.label[2] }}</tspan>
      </text>
    </g>
  </g>
</svg>