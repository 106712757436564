<div class="ft-form">
  <div class="ft-form-row main-container">   
    <div class="main-report-card">
      <div class="main-report-crop-img-container">
        <img class="report-img" [src]="report.image" [alt]="report.name | translate" />
      </div>       
    </div>
    <div class="main-report-summary">
      <p class="main-report-name">{{ report.name | translate }}</p>
      <div class="main-report-theams-label">
        <div class="main-report-theams-label-box"></div>
        <p class="main-report-theams-label-p">{{ report.variations.length }} {{ 'lbl_variations' | translate }}</p>
      </div>
      <p class="main-report-description">{{ report.mainDescription | translate }}</p>
    </div>
  </div>
  <br>
  <div class="ft-form-row"> 
    <p class="variations">{{ 'lbl_variations' | translate }}</p>  
  </div>
  <br>
  <div class="ft-form-row container">   
    <div *ngFor="let variation of report.variations; let i = index" class="report-card">
      <label class="report-crop-img-container" [for]="'report-variation' + variation.id" #imgDiv>
        <input type="radio" [id]="'report-variation' + variation.id" name="report-variation" [value]="variation.id" class="variation-radio" (click)="onReportSelect(imgDiv, variation.id)"/>
        <div class="variation-select"></div>
        <img class="report-img" [src]="variation.image" [alt]="variation.name | translate" />
        <div *ngIf="isNewReport(variation.createdDate)" class="new-badge"><p>{{ 'lbl_menu_new' | translate }}</p></div>
      </label>
      <p class="report-name">{{ variation.name | translate }}</p>
      <p class="report-description">{{ variation.description | translate }}</p>
    </div>
  </div>
  <div class="footer">   
    <button class="ft-btn" type="submit" form="ngFormPlace" [ngClass]="isAddButtonActive ? 'ft-btn-active' : 'ft-btn-inactive'" [disabled]="!isAddButtonActive" (click)="loadReport()">{{"lbl_select_menu" | translate}}</button>
    <button *ngIf="data.options.isCloseBtn" class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
  </div>
</div>