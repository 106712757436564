import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReportsMenuService } from '../reports-menu.service';
import { Router } from '@angular/router';
import { AppConstatnts } from 'src/app/common/constants/app-contstants';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from 'src/app/components/common/dialog/dialog-base/dialog-base.component';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';

@Component({
  selector: 'app-all-reports',
  templateUrl: './all-reports.component.html',
  styleUrls: ['./all-reports.component.scss']
})
export class AllReportsComponent extends DialogBaseComponent implements OnInit {
  @Input() data;
  @Output() onCompleted = new EventEmitter<any>();
  @ViewChild('imgDiv') imgDiv!: ElementRef;

  selectedContainer: HTMLElement | null = null;
  selectedReportId: number;
  reports: any[] = [];

  public isAddButtonActive: boolean;

  constructor(
    readonly reportsMenuService: ReportsMenuService, readonly router: Router, readonly dialogService: DialogService, private translateHandler: TranslateHandler, readonly ref1: ChangeDetectorRef) {
    super(dialogService, ref1);
  }

  ngOnInit() {
    this.reportsMenuService.getReports().subscribe((config) => {
      this.reports = config.sort((a: any, b: any) => {
        const isNewA = this.isNewReport(a.createdDate);
        const isNewB = this.isNewReport(b.createdDate);
        if (isNewA && !isNewB) {
          return -1;
        } else if (!isNewA && isNewB) {
          return 1;
        } else {
          const firstThreeA = this.translateHandler.translate(a.name).substring(0, 3).toLowerCase();
          const firstThreeB = this.translateHandler.translate(b.name).substring(0, 3).toLowerCase();
          return firstThreeA.localeCompare(firstThreeB);
        }
      });
    });
  }

  isNewReport(createdDate: string): boolean {
    const currentDate = new Date();
    const reportDate = new Date(createdDate);
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);
    return reportDate >= oneMonthAgo;
  }

  clearSelection(): void {
    this.clearAllRadioButtons();
    this.selectedReportId = null;
    this.selectedContainer = null;
    this.isAddButtonActive = false;
  }

  clearAllRadioButtons(): void {
    const radioButtons = document.querySelectorAll('.report-radio') as NodeListOf<HTMLInputElement>;
    radioButtons.forEach((radioButton) => (radioButton.checked = false));
  }

  onReportSelect(container: HTMLElement, reportId: any) {
    const isSameReportSelected = this.selectedReportId === reportId;
    this.selectedContainer?.classList.remove('selected');
    if (isSameReportSelected) {
      this.clearSelection();
    } else {
      container.classList.add('selected');
      this.selectedReportId = reportId;
      this.selectedContainer = container;
      this.isAddButtonActive = true;
    }
  }

  loadReport() {
    if (this.selectedReportId == null) return;
    const report = this.reports.find((report) => report.id === this.selectedReportId);
    if (report.variations?.length > 0) {
      this.dialogService.open('VariationsMain', { id: 0, title: report.name, viewMode: 1, reportInfo: report }).subscribe(() => {
        this.selectedContainer?.classList.remove('selected');
        this.clearSelection();
      });
    } else {
      this.close();
      this.router.navigate([AppConstatnts.routerModes[report.menuName]]);
    }
  }

  cancel() {
    this.close();
  }
}