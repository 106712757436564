import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, delay, first, takeWhile } from 'rxjs/operators';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import BookReport from 'src/app/common/models/BookReport';
import BookReportConfig from 'src/app/common/models/BookReportConfig';
import { BookReportApiService } from 'src/app/services/API/book-report-api.service';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { MessageDialogService } from '../../common/message-dialog/services/message-dialog.service';
import { EditorReferenceInfo } from 'src/app/common/models/editors/editor-reference-info';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { EditorMode,ErrorStatus, MultimediaTypes, ReferenceType } from 'src/app/common/enums/enums';
import { environment } from 'src/environments/environment';
import { BookReportParam } from 'src/app/common/models/BookReportParam';
import BookReportViewModel from 'src/app/common/models/BookReportViewModel';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { FamilyApiService } from 'src/app/services/API/family-api.service';
@Injectable({
  providedIn: 'root'
})
export class BookReportEngineService {
  bookReportUpdateSource: BehaviorSubject<BookReport>;
  isEngineLive: boolean;
  processId: string;
  
  constructor(
    private _http: HttpClient,
    private bookReportApiService: BookReportApiService,
    private messageDialogService: MessageDialogService,
    private translateHandler: TranslateHandler,
    private loadingService: LoadingService,
    private notifierService: NotifierV2Service,
    private dialogService: DialogService,
    private familyApiService: FamilyApiService,
  ) { }

  /**
   * start the bookReportService
   */
  public start(bookReport: BookReportViewModel): BehaviorSubject<BookReport> {
    this.processId = GuidGenerator.generate();
    this.loadingService.show(this.processId);
    this.bookReportUpdateSource = new BehaviorSubject<BookReport>(null);
    this.isEngineLive = true;
    var rootPersonId = this.notifierService.getCurrentRootMemberId().toString();
    this.bookReportApiService.getLast(bookReport.name,rootPersonId).pipe(catchError(error => {
      if (error.status == 404) {
        if(bookReport.reportStatus.type != ErrorStatus.Available) {
          this.bookReportUpdateSource.error(bookReport.reportStatus);
          return this.bookReportUpdateSource;
        }
        this.createReport(bookReport).pipe(catchError(err => {
          this.bookReportUpdateSource.error(err);
          return this.bookReportUpdateSource;
        })).subscribe(createdReport => {
          this.bookReportUpdateSource.next(createdReport);
          this.startReportService(createdReport.id);
        });

      }
      else {
        return this.bookReportUpdateSource;
      }

    })).subscribe(lastReport => {
      this.bookReportUpdateSource.next(lastReport);
      if (lastReport.isCompleted) {
        return;
      }


      this.startReportService(lastReport.id);
      setTimeout(() => {
        this.loadingService.hide(this.processId);
        this.showRedirectConfirmation(bookReport.name)
      }, environment.BOOK_REPORT_DELAY_INFO_POPUP_TIMEOUT)
    });

    return this.bookReportUpdateSource;
  }

  showRedirectConfirmation(reportName:string) {
    let confirmationDialogHeader = this.translateHandler.translate(`${reportName}.lbl_title`);
    let confirmationDialogfooter = this.translateHandler.translate("bookReport.info_click_ok_to_redirect_tree_editor");
    let confirmationDialogBody = this.translateHandler.translate('bookReport.err_delay_report_genarate');
    let lbl_ok = this.translateHandler.translate('lbl_ok');    
    let lbl_cancel = this.translateHandler.translate('lbl_cancel');
    
    this.messageDialogService.openDialog(confirmationDialogHeader, confirmationDialogBody, confirmationDialogfooter, lbl_ok, lbl_cancel)
      .pipe(first()).subscribe((response: boolean) => {
        if (response) {
          let ref: EditorReferenceInfo = {
            id: this.notifierService.getCurrentRootMemberId(),
            isBreadcrumbUpdate: true
          }
          this.notifierService.openEditor(EditorMode.TreeEditor, ref);
        }
        else {
          if (!this.bookReportUpdateSource.value.isCompleted) {
            this.loadingService.show(this.processId);
          }
        }
      }
      );
  }
  private startReportService(reportId: string) {
    this.bookReportApiService.getReport(reportId).pipe(delay(2000), takeWhile(() => this.isEngineLive)).subscribe(report => {
      this.bookReportUpdateSource.next(report);
      if (!report.isCompleted)
        this.startReportService(reportId);
    });
  }
  
  createReport(bookReport: BookReportViewModel): Observable<BookReport> {
    return this.bookReportApiService.createReport(bookReport);
  }

  getConfig(reportName: string): Observable<any> {
    let configJsonURL = `/assets/report/${reportName}/config/config.json`;
    return this._http.get<any>(configJsonURL);
  }

  public getBasePath(bookReport: BookReport): string {
    return "/assets/report/" + bookReport.name + "/";
  }

  public getBasePathWithLang(bookReport: BookReport): string {
    return this.getBasePath(bookReport) + bookReport.lang + "/"
  }

  public getImagePath(bookReport: BookReport, bookreportConfig: BookReportConfig): string {
    return this.getBasePath(bookReport) + "images/" + this.loadDefaultThemeName(bookreportConfig) + "/";
  }

  public getImagePathWithLang(bookReport: BookReport, bookreportConfig: BookReportConfig): string {
    return this.getBasePath(bookReport) + "images/" + this.loadDefaultThemeName(bookreportConfig) + "/" + bookReport.lang + "/";
  }

  private loadDefaultThemeName(bookreportConfig: BookReportConfig): string {
    let themeObj = bookreportConfig.themes.contentlist.find(
      (theme) => theme.id == bookreportConfig.themes.defaultId).name;
    return themeObj;
  }

  public endSession(): void {
    this.isEngineLive = false;
  }

}
