import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { Multimedia } from 'src/app/common/models/multimedia/multimedia';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { MultimediaApiService } from 'src/app/services/API/multimedia-api.service';
import { Crop } from 'src/app/common/models/multimedia/crop';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { MultimediaTypes, PopupViewMode } from 'src/app/common/enums/enums';
import { FTDate } from 'src/app/common/models/FTDate';

@Component({
  selector: 'app-multimedia-editor',
  templateUrl: './multimedia-editor.component.html',
  styleUrls: ['./multimedia-editor.component.scss']
})
export class MultimediaEditorComponent implements OnInit {

  constructor( private loadingService: LoadingService,
    private mediaApiService : MultimediaApiService) { }

  @Input() data;
  @Output() onCompleted = new EventEmitter<any>();
  isFileAdded : boolean = false;
  multmediaInfo : Multimedia = new Multimedia();
  mediaFile : any;
  mediaInputData = {
    acceptedMediaTypes : [],
    editMedia : new Multimedia()
  };
  isDirty: boolean = false
  
  responseData : {newMedia : Multimedia , 
                 deleteMedias : Multimedia[]} = new Object() as any;

  ngOnInit(): void {

    this.multmediaInfo.date  = new FTDate();
    this.responseData.deleteMedias = new Array<Multimedia>(); 
    this.mediaInputData = {
                            acceptedMediaTypes : this.data.mediaType,
                            editMedia : this.multmediaInfo
                          };

    if (this.data.response) {
      this.responseData = this.data.response;
    }

    if (this.data.editMediaId > 0) {
      // When user press edit scenario
      // Get Place API
      let processId = GuidGenerator.generate();
      this.loadingService.show(processId);   
      this.mediaApiService.getMultimediaById(this.data.editMediaId).subscribe((response: ResponseModel<Multimedia>) => {
        this.multmediaInfo = response.data;
        this.mediaInputData = {
          acceptedMediaTypes : this.data.mediaType,
          editMedia : this.multmediaInfo          
        };
        this.isFileAdded = true;
      }, (err) => {
        throw new CustomError("Multimedia Editor component => ngOnint() : " + err, 911, false);
      }).add(() => {
        this.loadingService.hide(processId);
      });
    }else{
      this.multmediaInfo.id = 0;
    }   
  }

  setMediaInfo($event) {
    if($event != null){ 
      let cropDetails = new Crop();
      cropDetails.X = $event.x;
      cropDetails.Y = $event.y;
      cropDetails.Width = $event.width;
      cropDetails.Height = $event.height;
      this.multmediaInfo.cropInfo = cropDetails;
      this.mediaFile = $event.file;
      console.log(this.mediaFile);
      this.multmediaInfo.fileName = $event.file.name;
      console.log(this.multmediaInfo.fileName);
      this.multmediaInfo.mediaType = $event.mediaType;
      console.log(this.multmediaInfo.mediaType);
      console.log("File Info", $event);
      this.isFileAdded = true;
      if(this.data.fromEdit){
        this.isDirty = false;
        this.data.fromEdit = null;
      }
      else if(this.data.fromEdit == null && this.data.editMediaId > 0){
        this.isDirty = true;
      }else{
        this.isDirty = true;
      }

    }else{
      this.multmediaInfo.originalMediaUrl = null;
      this.multmediaInfo.mediaUrl = null;
      this.multmediaInfo.fileName = null;
      this.multmediaInfo.cropInfo = null;
      this.isFileAdded= false;
    }
  }


  add(){    
    let formData = new FormData();
    formData.append('files', this.mediaFile, this.mediaFile.name);
    formData.append('basicInfo', JSON.stringify(this.multmediaInfo));

    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    
    if (this.multmediaInfo.id == 0) {
      //Create multimedia API
      this.mediaApiService.createMultimedia(formData).subscribe((response: ResponseModel<Multimedia>) => {
        this.multmediaInfo = response.data;
        this.responseData.newMedia = this.multmediaInfo;
        this.completeAction();
      }, (err) => {
        throw new CustomError("Multimedia Editor component => add() : " + err, 911, false);
      }).add(() => {
        this.loadingService.hide(processId);
      });
    }else{
      //Update multimedia API
      this.mediaApiService.updateMultiMedia(formData).subscribe((response: ResponseModel<Multimedia>) => {        
        this.multmediaInfo = response.data;
        let timeStamp = performance.now();
        this.multmediaInfo.mediaUrl = (this.multmediaInfo.mediaUrl != null) ? this.multmediaInfo.mediaUrl + '&v=' + timeStamp : this.multmediaInfo.mediaUrl;        
        this.responseData.newMedia = this.multmediaInfo;
        this.completeAction();
      }, (err) => {
        throw new CustomError("DateHelper => FormatDate() : " + err, 911, false);
      }).add(()=>{     
        this.loadingService.hide(processId);
      });
    }
    
  }

  completeAction(){
  // Avoid closing of popup when viewing list
  if(this.data.options.isSwitchToParent)
    this.onCompleted.next({data: this.responseData});
  this.data.viewMode = PopupViewMode.GalleryView;
  this.data.editMediaId = 0;
  this.onCompleted.unsubscribe();
  }

  cancel(){
    this.completeAction();
  }

  setFormatedDate($event) {
    this.multmediaInfo.date.genealogyDate = $event.genealogyDate;
  }
}
