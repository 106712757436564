<div class="ft-form">
    <div class="content">
        <!-- form title -->
        <form class="form" id="ngFormSource" #f="ngForm" (ngSubmit)="f.form.valid && addSource()">
            <div class="ft-form-row">
                <span class="title">{{titleTxt}}</span>
            </div>
            
            <div class="ft-form-row col2-vf">
                <div class="border-outline">
                    <!-- images carousel -->                
                    <app-image-carousel-temp 
                        [inImages] = "sourceComponentModel.multimedias"
                        [defaultImage]="defaultCarouselImage"
                        (action)="carouselActionClicked($event)">
                    </app-image-carousel-temp>
                </div>
                <div>
                    <div class="ft-form-row col1-v100 source-title">
                        <div class="ft-form-input">
                            <label>{{"lbl_title" | translate}}</label>
                            <input type="text" [(ngModel)]="sourceComponentModel.title" #title="ngModel" name="title"
                            [required]="(sourceComponentModel.title) ? false : true" [class.border-wrong] = "f.submitted && title.invalid" (keydown)="isDirty=true">
                            <div *ngIf="f.submitted && title.invalid" class="form-control-feedback">
                                <div *ngIf="title.errors.required">
                                    <p class="input-s-wrong">
                                        {{"val_source_validation_message" | translate}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ft-form-row col2-v50v50 source-period">                    
                            <div class="ft-form-input">
                                <label>{{"lbl_period" | translate}}</label>
                                <input type="text" [(ngModel)]="sourceComponentModel.period" [ngModelOptions]="{standalone: true}" #period="ngModel" name="period" (keydown)="isDirty=true">
                            </div>
                            <div class="ft-form-input">
                                <label>{{"lbl_author" | translate}}</label>
                                <input type="text" [(ngModel)]="sourceComponentModel.author" [ngModelOptions]="{standalone: true}" #author="ngModel" name="author" (keydown)="isDirty=true">
                            </div>                    
                    </div>
                </div>
            </div>

            <div class="ft-form-row col2-v50v50">                    
                <div class="ft-form-input">
                    <label>{{"lbl_publication" | translate}}</label>
                    <input type="text" [(ngModel)]="sourceComponentModel.publication" [ngModelOptions]="{standalone: true}" #publication="ngModel" name="publication" (keydown)="isDirty=true">
                </div>
                <div class="ft-form-input">
                    <label>{{"lbl_repository" | translate}}</label>
                    <input type="text" [(ngModel)]="sourceComponentModel.repository" [ngModelOptions]="{standalone: true}" #repository="ngModel" name="repository" (keydown)="isDirty=true">
                </div>                    
            </div>         
            <div class="ft-form-row col1-v100">                    
                <div class="ft-form-input">
                    <label>{{"lbl_transcript" | translate}}</label>
                    <input type="text" [(ngModel)]="sourceComponentModel.transcript" [ngModelOptions]="{standalone: true}" #transcript="ngModel" name="transcript" (keydown)="isDirty=true">
                </div>                      
            </div>        
            <div class="ft-form-row col1-v100">                    
                <div class="ft-form-input">
                    <label>{{"lbl_note" | translate}}</label>
                    <input type="text" [(ngModel)]="sourceComponentModel.notes" [ngModelOptions]="{standalone: true}" #notes="ngModel" name="notes" (keydown)="isDirty=true">
                </div>                  
            </div>  
        </form>
        <div  class="ft-form-row col1-v100" style="height: 35%; margin-top: 25px;">
            <app-event-list style="width: 100%;" [eventList]="eventList" [data]="data" [eventConfig] = "eventConfig" ></app-event-list>
        </div>
    </div>
    <div class="footer">
        <button class="ft-btn " type="submit" form="ngFormSource" [disabled]="!isDirty || title.invalid"  [ngClass]="(isDirty && !title.invalid) ? 'ft btn ft-btn-active' : 'ft btn ft-btn-inactive'">{{"lbl_add" | translate}}</button>
        <button class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
    </div>
</div>
