import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuMainComponent } from './menu-main/menu-main.component';
import { ProfileMainComponent } from './profile-main/profile-main.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ReportsMenuComponent } from './reports-menu/reports-menu.component';
import { AllReportsComponent } from './reports-menu/all-reports/all-reports.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VariationsComponent } from './reports-menu/variations/variations.component';
import { VariationsMainComponent } from './reports-menu/variations-main/variations-main.component';
import { ReportmanagerModule } from '../reportmanager/reportmanager.module';


@NgModule({
  declarations: [MenuMainComponent, ProfileMainComponent, ReportsMenuComponent, AllReportsComponent, VariationsMainComponent, VariationsComponent],
  imports: [
    CommonModule,
    ReportmanagerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    MenuMainComponent
  ]
})
export class MainmenuModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
