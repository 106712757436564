import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, EventEmitter, Output, ViewChild, ElementRef, KeyValueDiffer, KeyValueDiffers, DoCheck } from '@angular/core';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { TooltipService } from 'src/app/services/UI/tooltip.service';
import { MessageDialogService } from '../../message-dialog/services/message-dialog.service';
import { TooltipHeadPosition } from '../../tooltip/models/tooltip-head-position';
import { TooltipTestContentComponent } from '../../tooltip/tooltip-test-content/tooltip-test-content.component';
import { EventComponentModel } from '../models/event-component-model';
import { FTDate } from 'src/app/common/models/FTDate';
import { PlaceBase } from 'src/app/common/models/place/place-base';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { EventApiService } from 'src/app/services/API/event-api.service';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { EditorMode, ReferenceType, MultimediaTypes, DropMenuAction, PopupViewMode } from 'src/app/common/enums/enums';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { DropMenuItem } from '../../drop-menu/models/DropMenuItem';
import { Multimedia } from 'src/app/common/models/multimedia/multimedia';
import { Witness } from 'src/app/components/familyeditor/models/family-component-model';
import { StringFormatter } from 'src/app/common/helpers/string-formatter';
import { MediaItem } from '../../media-carousel/models/media-item';
import { DropMenu } from '../../drop-menu/models/DropMenu';
import { EventDetailMediaComponent } from '../event-tips/event-detail-media/event-detail-media.component';
import { SourceCitationViewModel } from 'src/app/common/models/sourceCitation/source-citation-view-model';
import { MultiMediaBasicInfo } from 'src/app/common/models/MultiMediaBasicInfo';
import { MultimediaApiService } from 'src/app/services/API/multimedia-api.service';
import { EventDetailSourceComponent } from '../event-tips/event-detail-source/event-detail-source.component';
import { SourceCitationAPIService } from 'src/app/services/API/sourceCitation-api.service';
import { EventViewBase } from '../models/event-view-base';
import { environment } from 'src/environments/environment';
import { Validate } from 'src/app/common/helpers/validate';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss']
})
export class EventFormComponent implements OnInit, DoCheck {

  // Variable declaration  
  formTitle: string;
  eventDropdownList: string[];
  selectedEventType: string = "";
  isEditorMode: boolean;
  eventNameText: string = "";
  defaultView: boolean;
  referenceType: ReferenceType;
  defaultCarouselImage: string = "assets/images/icons/event.svg";
  midTooltipPositionSwapRatio: number = 0.9;
  disableForm: boolean = false;

  //Decorator Declarations 
  @Input() title: string;
  @Input() data: any;
  @Input() eventList: any;
  @Output() refreshEventList = new EventEmitter<number>();
  @ViewChild('eventFormContainer') eventContainer: ElementRef;

  eventWitness: Witness[];
  eventMultimedia: MultiMediaBasicInfo[];
  eventSourceCitations: SourceCitationViewModel[];
  existingEventList: EventViewBase[];
  
  //Dirty check
  canChangeRequestSubscription: any;
  individualDiffer: KeyValueDiffer<string, any>;
  ngDocheckCount: number = 0;
  isDirty = false;

  // Object declaration
  eventModel: EventComponentModel;
  mediaItems: MediaItem[];
  sourceItems: MediaItem[];
  dropMenuListByItem = {
    "Mulitmedia": [<DropMenuItem>{ action: DropMenuAction.Edit, isEnabled: true, isVisible: true },
                   <DropMenuItem>{ action: DropMenuAction.Unlink, isEnabled: true, isVisible: true },
                   <DropMenuItem>{ action: DropMenuAction.Info, isEnabled: true, isVisible: true }],

    "Source"    : [<DropMenuItem>{ action: DropMenuAction.Edit, isEnabled: true, isVisible: true },
                   <DropMenuItem>{ action: DropMenuAction.Unlink, isEnabled: true, isVisible: true },
                   <DropMenuItem>{ action: DropMenuAction.Info, isEnabled: true, isVisible: true }],

    "Witness"   : []             
  }

  constructor(private translateHandler          : TranslateHandler,
              private dialogService             : DialogService,
              private eventApiService           : EventApiService,              
              private loadingService            : LoadingService,
              private changeDetectorRef         : ChangeDetectorRef,
              private parentCommunicatorService : ParentCommunicatorService,
              private messageDialogService      : MessageDialogService,
              private stringFormatter           : StringFormatter,
              private tooltipService            : TooltipService,   
    	        private sourceCitationApiService  : SourceCitationAPIService,
              private mediaApiService           : MultimediaApiService,
              private differs: KeyValueDiffers,
              private validate: Validate) 
    {
    this.eventModel = new EventComponentModel();
    this.eventModel.place = new PlaceBase();
    this.isEditorMode = false;
    this.validate = new Validate();
  }

  ngOnInit(): void {
    this.mediaItems = new Array<MediaItem>();
    this.sourceItems = new Array<MediaItem>();  
    this.eventMultimedia = new Array<MultiMediaBasicInfo>();
    this.eventSourceCitations = new Array<SourceCitationViewModel>();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.referenceType = (this.data.editorMode == EditorMode.IndividualEditor) ? ReferenceType.Individual : ReferenceType.Family;
    this.getEventTypeList(this.referenceType);
    if (this.data.eventId > 0) {
      this.isEditorMode = true;
      this.fillEventDetails();
      this.defaultView = false;
    }
    else {
      this.defaultView = true;
      this.eventModel = new EventComponentModel();
      this.eventModel.place = new PlaceBase();
      this.eventModel.multimedia = new Array<Multimedia>();     
      this.eventModel.sourceCitations = new Array<SourceCitationViewModel>();
      this.eventModel.witness = new Array<Witness>();
      this.isEditorMode = false;
      this.eventNameText = this.translateHandler.translate("lbl_event").toLowerCase();
      this.formTitle = this.getTitle();

      //Dirty Check
      this.data.isEventDirty = false;
      if (this.eventModel) {
        this.ngDocheckCount = 0;
        this.individualDiffer = this.differs.find(this.eventModel).create();
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  ngDoCheck(): void {
    this.disableForm = this.data.isEditorDirty;
    if (this.individualDiffer) {
      let changes = this.individualDiffer.diff(this.eventModel);
      if (changes) {
        if (this.ngDocheckCount > 0) {
          this.data.isEventDirty = true;
          this.isDirty = true;
        }
        this.ngDocheckCount++
      }
    }
  }
  fillEventDetails() {
    let timeStamp = performance.now();
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.eventApiService.getEvent(this.data.eventId).subscribe((response: ResponseModel<EventComponentModel>) => {
      this.eventModel = response.data;
      this.eventModel.referenceId = this.data.id;
      this.eventModel.referenceType = this.referenceType;
      this.eventModel.multimedia = response.data?.multimedia?.filter(t => t.mediaType === MultimediaTypes.PHOTO)
      .map(item => {
        let url = (item.mediaUrl !== null && item.mediaUrl !== undefined) ? item.mediaUrl + '&v=' + timeStamp : item.mediaUrl;
        item.mediaUrl = url;      
        return item
      }) ?? [];

      //Dirty Check
      this.data.isEventDirty = false;
      if (this.eventModel?.id) {
        this.ngDocheckCount = 0;
        this.individualDiffer = this.differs.find(this.eventModel).create();
        this.changeDetectorRef.detectChanges();
      }
      if (this.data.isCustomEvent) {
        this.eventDropdownList.push(this.eventModel.name)
      }
      this.selectedEventType = this.eventModel.name.toUpperCase();
      this.eventNameText = (this.selectedEventType !== 'undefined' && this.selectedEventType !== '') ? (this.data.isCustomEvent ? this.stringFormatter.capitalizeFirstLetter(this.selectedEventType.toLowerCase()) : this.translateHandler.translate("event_" + this.selectedEventType.toLowerCase())) : '';
      this.formTitle = this.getTitle();
      this.defaultView = false;
      this.setInMedia();
      this.setInSources();
    }, (err) => {
      this.defaultView = true;
      this.data.isEventDirty = false;
      throw new CustomError("EventMain => fillEventDetails() : " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
    });
  }

  getEventTypeList(referenceType: number) {
    this.eventApiService.getEventTypeList(referenceType).subscribe((response: ResponseModel<string[]>) => {
      this.eventDropdownList = response.data;
    }, (err) => {
      throw new CustomError("EventFormComponent => getEventTypeList() : " + err, 911, false);
    })
  }

  selectEventType() {
    this.eventModel.name = this.selectedEventType;
    this.defaultView = this.eventModel.name != null ? false : true;
    this.validateBirthDeathDuplicateEvent();
  }
  validateBirthDeathDuplicateEvent() {
    // Ensure existingEventList is defined
    this.existingEventList = this.eventList;
    if (!this.existingEventList) {
        return;
    }
      // Filter existing events for "BIRTH" or "DEATH" events
    const duplicateEvents = this.existingEventList.filter(event =>
      environment.DUPLICATE_VALIDATION_EVENTS.includes(event.name.toUpperCase())
    );

    // Filter to find duplicates of the current event
    const duplicateEvent = duplicateEvents.filter(event =>
        event.name.toUpperCase() === this.eventModel.name.toUpperCase()
    );

    // If duplicates exist, show an error and update the event type list
    if (duplicateEvent.length > 0) {
        const eventType = "event_" + this.eventModel.name.toLowerCase();

        this.messageDialogService.openWarn(
          this.translateHandler.translate("val_warning"),
          this.translateHandler.translate("individual.lbl_event_duplication")+" ("+this.translateHandler.translate(eventType)+")","");
    }
}

setFormattedDate($event) {
  this.eventModel.date ? this.eventModel.date : this.eventModel.date = new FTDate(); 
  this.validate.isGenealogyDateSet(this.eventModel.date, $event);
}

  setPlaceForEvent(place: any) {
    if (this.eventModel && this.eventModel.place) {
      this.eventModel.place = place;
      this.eventModel.placeId = place.id;
    }
  }

  getTitle() {
    return this.translateHandler.translate("lbl_add_event_to_family", [this.eventNameText, this.title]) ?? ""
  }

  getPlacePopupTitle() {
    return this.translateHandler.translate("lbl_link_place", [this.eventNameText, this.title]) ?? ""
  }

  getSourcePopupTitle() {
    return this.translateHandler.translate("lbl_link_place", [this.eventNameText, this.title]) ?? ""
  }

  //Add/edit all media
  openMediaEditor(mediaId: any, viewMode: number) {
    this.dialogService.setHalf().open("Multimedia",
      {
        id: 0,
        reference: {
          id: this.eventModel.id ?? 0, // Event ID
          type: ReferenceType.Event // Event type
        },
        title: this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_media").toLowerCase(),
        this.translateHandler.translate("lbl_event").toLowerCase()]),
        mediaType: [MultimediaTypes.PHOTO, MultimediaTypes.DOC, MultimediaTypes.AUDIO, MultimediaTypes.VIDEO],
        viewMode: viewMode,
        editMediaId: mediaId, // MediaId which supposes to be edited
        isStandAlone: false
      }
    )
      .subscribe(response => {
        if (response.data) {
          response.data.deleteMedias.forEach(element => {
            let index = this.eventModel.multimedia.findIndex(i => i.id === element.id);
            if (index >= 0) {
              this.eventModel.multimedia.splice(index, 1);
            }
          });

          if (response.data.newMedia) {
            let newMedia = response.data.newMedia;
            newMedia.isNonProfile = true;
            newMedia.isNewlyAdded = true;
            newMedia.mediaUrl = this.getImageMediaUrl(newMedia);
            let mediaIndex = this.eventModel.multimedia.findIndex(t => t.id == newMedia.id);
            if (mediaIndex < 0)
              this.eventModel.multimedia.push(newMedia);
            else {
              this.eventModel.multimedia[mediaIndex] = newMedia;
            }
            this.eventModel.multimedia = [... this.eventModel.multimedia];
          }
        }
        this.setInMedia();
      })
  }

  getImageMediaUrl(media: Multimedia) {
    let timeStamp = performance.now();
    let url = (media.mediaUrl != null) ? media.mediaUrl + '&v=' + timeStamp : media.mediaUrl;
    return media.mediaType === MultimediaTypes.PHOTO ? url ?? "/assets/images/Male.png" : "assets/nonimage-preview/Large-Icons/media_type_" + media.mediaType + ".svg"
  }

  addSource() {
    this.dialogService.setHalf().open("Source",
      {
        // Setting the properties for the request 
        id: 0,
        reference: {
          id: 0,
          type: ReferenceType.Source,
          eventId : this.eventModel.id
        },        
        titleText : this.eventNameText + " " +this.title,
        viewMode: PopupViewMode.GalleryView, // Gallery or editor view
        editSourceId: 0, // SOurce which supposes to be edited
        isStandAlone: false
      }
    )
      .subscribe(response => {
        if (response.data) {             
          response.data.deleteSourcesIds.forEach(id => {           
            this.eventModel.sourceCitations.filter(i => i.sourceBase.id === id).forEach(el =>{
              let index = this.eventModel.sourceCitations.findIndex(i => i.sourceBase.id === id);
              if (index >= 0) {
                this.eventModel.sourceCitations.splice(index, 1);  
              }
            })   
            this.setInSources();
            this.changeDetectorRef.detectChanges();          
          });

          if (response.data.newSourceCitation) {
            this.eventModel.sourceCitations.push(response.data.newSourceCitation);
            this.eventModel?.sourceCitations?.filter(el => el.sourceBase.id == response.data.newSourceCitation.sourceBase.id)?.forEach(element => {
              element.sourceBase = response.data.newSourceCitation.sourceBase;
            });
            this.setInSources();
            this.changeDetectorRef.detectChanges();
          }
        }
      })
      this.isDirty=true;
  }

  addSourceCitation(sourceCitation: any){
    this.dialogService.open("SourceCitation",{
      // Todo set request properties
      id: sourceCitation.id,
      responseData: {sourceCitationInfo : sourceCitation , deleteSourcesIds : [] },
      title: this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_citation").toLowerCase(), this.eventNameText + " " +this.title]), // Todo : Need to translate and set title
    }).subscribe( response => {  
      if(response.data){   
        this.isDirty = true;
        var sourceCitationIndex =  this.eventModel.sourceCitations.findIndex(t=>t.id == sourceCitation.id);
        if(sourceCitationIndex > 0){
          this.eventModel.sourceCitations[sourceCitationIndex] = response.data;
        }
        // Update eventModel
      }  
    })
  }

  addWitness() {
    this.showNotImplemented();
  }

  showNotImplemented() {
    this.parentCommunicatorService.send(ParentMessageType.ShowInformation, "not_implemented");
  }

  getInImages() {
    return this.eventModel?.multimedia?.filter(t => t.mediaType == MultimediaTypes.PHOTO && !t.isNonProfile) ?? [];
  }
  
  setInMedia() {
    this.mediaItems = this.eventModel?.multimedia?.filter(t => t.isNonProfile)
      .map(item => {
        item.mediaUrl = (item.mediaType != MultimediaTypes.PHOTO) ? "assets/nonimage-preview/Large-Icons/media_type_" + item.mediaType + ".svg" : item.mediaUrl
      let mediaItem = new MediaItem(item.id, (item.caption ? item.caption : item.fileName), item.mediaUrl, this.createDropMenu(item,"Mulitmedia"));
        return mediaItem
      }) ?? [];
  }

  setInSources() {   
    let timeStamp = performance.now();
    this.sourceItems = this.eventModel?.sourceCitations.map(item => {
      let mediaUrl = item?.sourceBase?.mediaUrl != null ? item?.sourceBase?.mediaUrl + '&v=' + timeStamp : item?.sourceBase?.mediaUrl;
      let mediaItem = new MediaItem(item.id, item.sourceBase.title, mediaUrl ??  "/assets/images/icons/source.svg", this.createDropMenu(item,"Source"));
      return mediaItem
    }) ?? [];
    this.changeDetectorRef.detectChanges();
  }

  createDropMenu(ref: any , type : string) {   
    let dropMenu = new DropMenu();
    dropMenu.ref = ref;
    dropMenu.menuItems = this.dropMenuListByItem[type];                        
    return dropMenu;
  }

  sourceCarouselActionClicked($event) {
    let action = $event.action;

    if (action == DropMenuAction.Edit) {

      this.addSourceCitation($event.reference)
    }
    if (action == DropMenuAction.Unlink) {

      // Unlink child confirmation box
      let unlinkMessage = this.translateHandler.translate("cnf_unlink_source_confirmation_body");
      this.messageDialogService.openUnlinkfirmation(unlinkMessage).subscribe((res: boolean) => {
        if (res) {
          if ($event.reference != null) {
            this.isDirty = true;
            let index = this.eventModel?.sourceCitations.findIndex(i => i.id === $event.reference.id);
            this.eventModel?.sourceCitations.splice(index, 1);
            this.setInSources();
          }
        }
      });
    }
    if(action == DropMenuAction.Info){
      this.showToolTipSource($event);
    }
  } 

  mediaCarouselActionClicked($event) {  
    let selectedMultimedia = $event.reference;
    let action = $event.action;
    

    if (action == DropMenuAction.Edit) {   
      this.openMediaEditor(selectedMultimedia.id, 2);
    }
    if (action == DropMenuAction.Unlink) {
      // Unlink child confirmation box     
      let unlinkMessage = this.translateHandler.translate("cnf_unlink_confirmation_body");
      this.messageDialogService.openUnlinkfirmation(unlinkMessage).subscribe((res: boolean) => {
        if (res) {          
          this.unlinkMedia(selectedMultimedia);
          this.setInMedia();
        }
      });
    }
    if(action == DropMenuAction.Info){
      this.showToolTipMultimedia($event);
    }
  }
  
  showToolTipMultimedia($event) {
    this.mediaApiService.getMultimediaById($event.reference.id).subscribe((response: ResponseModel<MultiMediaBasicInfo>) => {
      this.eventMultimedia.length = 0;
      this.eventMultimedia.push(response.data);    
      var data = {
        name: "",
        value: this.eventMultimedia
      };
      this.tooltipService.show($event.bounds, TooltipHeadPosition.Left, "white", EventDetailMediaComponent, data);
    }, (err) => {
      throw new CustomError("Event form component => showToolTipMultimedia() : " + err, 911, false);
    }).add(() => {    
    }); 
  }

  showToolTipSource($event) {
      this.eventSourceCitations.length = 0;
      this.eventSourceCitations.push($event.reference)
      var data = {
          name: "",
          value: this.eventSourceCitations
        };
        this.tooltipService.show($event.bounds, TooltipHeadPosition.Bottom, "white", EventDetailSourceComponent, data);     
  }

  unlinkMedia(selectedMultimedia: Multimedia): number {
    if (selectedMultimedia != null) {
      let index = this.eventModel.multimedia.findIndex(i => i.id === selectedMultimedia.id);
      this.eventModel.multimedia.splice(index, 1);
      this.eventModel.multimedia = [...this.eventModel.multimedia];
      return index;
    }
  }

  getMultimediaDropMenu() {
    return [
      <DropMenuItem>{ action: DropMenuAction.Edit, isEnabled: true, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.Unlink, isEnabled: true, isVisible: true }
    ];
  }  


  carouselActionClicked($event) {    
    let selectedMultimedia = $event.reference;
    let action = $event.action;

    if (action == DropMenuAction.Upload) {
      // Set view mode as 1 (Gallery View)
      this.openImageEditor(0, 2);
    }
    if (action == DropMenuAction.Gallery) {
      // Set view mode as 2 (Edit View)
      this.openImageEditor(0, 1);
    }
    if (action == DropMenuAction.Edit) {
      // Set view mode as 2 (Edit View)
      this.openImageEditor(selectedMultimedia.id, 2);
    }
    if (action == DropMenuAction.Unlink) {
      // Unlink child confirmation box
      let unlinkMessage = this.translateHandler.translate("cnf_unlink_confirmation_body");
      this.messageDialogService.openUnlinkfirmation(unlinkMessage).subscribe((res: boolean) => {
        if (res) {
          let defaultImageIndex = this.unlinkMedia(selectedMultimedia);
          this.setNextImageAsPrimary(defaultImageIndex);
        }
      });
    }
    if (action == DropMenuAction.SetAsPrimary) {
      this.setImageAsPrimary(selectedMultimedia);
    }
  }

  setImageAsPrimary(selectedMultimedia: Multimedia) {
    if (selectedMultimedia != null) {
      if (!selectedMultimedia.isPrimary) {
        this.eventModel.multimedia.map(m => m.isPrimary = m.id === selectedMultimedia.id);
        this.eventModel.multimedia = [...this.eventModel.multimedia];
      }
    }
  }

  //Add/edit Image
  openImageEditor(mediaId: any, viewMode: number) {
    this.dialogService.setHalf().open("Multimedia", {
      // Set id to 0 if StandAlone
      id: this.eventModel.multimedia.find(t => t.isPrimary)?.id ?? 0,
      reference: {
        id: this.eventModel.id ?? 0, // Event Id
        type: ReferenceType.Event // Event type
      },
      title: this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_pictures").toLowerCase(),
      this.translateHandler.translate("lbl_event").toLowerCase()]),
      mediaType: [MultimediaTypes.PHOTO],
      viewMode: viewMode, // Gallery or editor view
      editMediaId: mediaId, // MediaId which supposes to be edited
      isStandAlone: false
    }
    )
      .subscribe(response => {
        if (response.data) {
          response.data.deleteMedias.forEach(element => {
            let index = this.eventModel.multimedia.findIndex(i => i.id === element.id);
            if (index >= 0) {
              this.eventModel.multimedia.splice(index, 1);
              this.setNextImageAsPrimary(index);
            }
          });

          if (response.data.newMedia) {
            response.data.newMedia.isNonProfile = false;
            response.data.newMedia.isNewlyAdded = true;
            let mediaIndex = this.eventModel.multimedia.findIndex(t => t.id == response.data.newMedia.id);
            if (mediaIndex < 0)
              this.eventModel.multimedia.push(response.data.newMedia);
            else {
              this.eventModel.multimedia[mediaIndex] = response.data.newMedia;
            }
            // When adding new image to image carousel , set this as primary
            this.eventModel.multimedia.map(m => m.isPrimary = m.id === response.data.newMedia.id);
            this.eventModel.multimedia = [... this.eventModel.multimedia];
          }
          this.setInMedia();
        }
      })
  }

  //Set next image when primary image unlinked
  setNextImageAsPrimary(imageIndex: number) {
    let isPrimaryImage = this.eventModel.multimedia?.find(t => t.isPrimary) ?? null;
    if (!isPrimaryImage && this.eventModel.multimedia.length > 0) {
      if (this.eventModel.multimedia[imageIndex > 0 ? (imageIndex - 1) : 0].isNonProfile) {
        this.eventModel.multimedia.find(t => t.mediaType == MultimediaTypes.PHOTO && !t.isNonProfile).isPrimary = true;
      } else {
        this.eventModel.multimedia[imageIndex > 0 ? (imageIndex - 1) : 0].isPrimary = true;
      }
    }
  }

  saveEvent() {
    let eventObj = { ...this.eventModel }
    if (eventObj.id)
      this.updateEvent();
    else
      this.createNewEvent();
  }

  //update event
  updateEvent() {
    let eventObj = { ...this.eventModel }
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.eventApiService.updateEvents(eventObj).subscribe((response: any) => {
      this.loadingService.hide(processId);
      this.data.isEventDirty = false;
    }, (err) => {
      this.loadingService.hide(processId);
      throw new CustomError("Event Editor => updateEvent() : " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
      this.refreshEventList.emit(1);
    })
  }

  //create event
  createNewEvent(){     
    this.eventModel.referenceId = this.data.id;
    this.eventModel.referenceType = this.referenceType;
    let eventObj = { ...this.eventModel }
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.eventApiService.createEvent(eventObj).subscribe((response: any) => {
      this.loadingService.hide(processId);
    }, (err) => {
      throw new CustomError("Event Editor => createEvent() : " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
      this.refreshEventList.emit(1);
    })
  }

  cancel(){
    this.refreshEventList.emit(1);
  }
  
  scrollMMRight() {
    var ele = document.getElementById("scroller");
    this.scrollRight(ele);
  }

  scrollMMLeft() {
    var ele = document.getElementById("scroller");
    this.scrollLeft(ele);
  }

  scrollRight(ele: any) {
    var width = ele.getBoundingClientRect().width;
    ele.scrollBy(width / 2, 0);
  }

  scrollLeft(ele: any) {
    var width = ele.getBoundingClientRect().width;
    ele.scrollBy(-width / 2, 0);
  }

}
