import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { DialogService } from "src/app/services/UI/dialog.service";
import { IndividualEventBase } from "src/app/common/models/individual/individual-event-base";
import { GuidGenerator } from "src/app/common/helpers/guid-generator";
import { LoadingService } from "src/app/services/UI/loading.service";
import { IndividualApiService } from "src/app/services/API/individual-api.service";
import { FamilyIndividual } from "src/app/components/familyeditor/models/family-individual";
import { ChildRealtionshipViewModel } from "src/app/components/familyeditor/models/child-relationship-view-model";
import { FamilyViewModel } from "src/app/components/familyeditor/models/family-view-model";
import { FamilyIndividuals } from "src/app/common/enums/enums";
import { IndividualRequestModel } from "src/app/common/models/individual/individual-request-model";
import { CustomError } from "src/app/common/helpers/custom-error";

@Component({
  selector: "app-individual-adder",
  templateUrl: "./individual-adder.component.html",
  styleUrls: ["./individual-adder.component.scss"],
})
export class IndividualAdderComponent implements OnInit {
  // Variable declaration
  title: string;
  profileImageUrl: string;
  displayName: string;
  relationshipType: string;
  isDefault: boolean;
  showBloodlineDefaultOption: boolean = false;
  showAdoptedDefaultOption: boolean = false;
  showFosterDefaultOption: boolean = false;
  showSpecialDefaultOption: boolean = false;
  defaultView: boolean = true;
  IMG_BASE_URL: string = "assets/images/defaults";

  //Objects declaration
  childRelationshipViewModel: ChildRealtionshipViewModel;
  individualInfo: IndividualEventBase;
  selectedChild: FamilyIndividual;
  parentFamilies: FamilyViewModel[];
  individualModel: IndividualRequestModel;
  defaultImage: string;
  isNameInvalid: boolean = false;
  isAlreadyExistName: boolean = false;
  isNameEmpty: boolean;
  isFormValid: boolean = false;
  familyId: number = 0;
  newChildId: number = 0;
  @Input() data: any;

  constructor(
    ref1: ChangeDetectorRef,
    private loadingService: LoadingService,
    private individualApiService: IndividualApiService,
    private dialogSerivce: DialogService
  ) {}

  ngOnInit(): void {
    this.individualModel = new IndividualRequestModel();
    this.familyId = this.data.familyId;
    this.title = this.data.title;
    this.data.options.primaryTabName = "lbl_add_children";
    this.setGenderAndProfileImage("U");
  }

  setGenderAndProfileImage(newGender: string): void {
    this.individualModel.gender = newGender;
    this.defaultImage =
      newGender == "M" ? "assets/images/Male.png" :
      newGender == "F" ? "assets/images/Female.png"
                       : "assets/images/unknown.svg";
  }

  validateForm(): void {
    this.isFormValid = false;
    if (this.individualModel.firstName)
    {
      this.isFormValid = true;
    }
  }

  cancel(): void {
    if(this.newChildId > 0){
    this.individualApiService.deleteIndividual(this.newChildId).subscribe(
      (responseDelete: any) => {
        if (responseDelete.data) {  
          this.isFormValid = false;
        }
      },
      (err) => {
        throw new CustomError(
          "IndividualEditorComponent => delete() => deleteIndividualAPI() : " +
            err,
          911,
          false
        );
      }
    );
    }
    this.dialogSerivce.complete({ data: null });
  }

  addNewChildToFamily(): void {
    this.isFormValid = false;
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.individualModel.gender = this.individualModel.gender.trim();
    this.individualModel.memberName = this.formatMemberName(this.individualModel.firstName, this.individualModel.lastName)
    this.individualModel.relatedFamilyId = this.familyId;

    this.individualApiService
      .createChild(this.individualModel)
      .subscribe((child: any) => {
        // Navigate to individual editor
        if (child.data.id > 0) {
          this.newChildId = child.data.id;
          this.dialogSerivce
            .setHalf()
            .open("Relationship", {
              id: 0,
              title: this.title,
              relationshipMode: FamilyIndividuals.Child,
              individualInfo: {
                individualId: child.data.id,
                profileImageUrl:
                  child.data.gender == "M" ? "assets/images/Male.png" : child.data.gender == "F" ? "assets/images/Female.png" : "assets/images/unknown.svg",
                displayName: this.individualModel.memberName,
              },
            })
            .subscribe((response) => {
              if(typeof response === 'undefined'){
                this.individualApiService.deleteIndividual(this.newChildId).subscribe(
                  (responseDelete: any) => {
                    if (responseDelete.data) {  
                      this.isFormValid = true;
                    }
                  }
                );
                this.dialogSerivce.complete({ data: null });
                return null;
              }
              if (!response.data) {
                return null;
              }
              //Create new child object
              let newChild = new FamilyIndividual();
              newChild.id = child.data.id;
              newChild.relationShipType = response.data.relationShipType;
              newChild.profileImageUrl =
                child.data.gender == "M" ?  "assets/images/Male.png" : child.data.gender == "F" ? "assets/images/Female.png" : "assets/images/unknown.svg";
              newChild.isDefault = response.data.isDefault;
              newChild.displayName = child.data.fullName;
              newChild.birthEvent = response.data.birthEvent;

              this.dialogSerivce.complete({ data: newChild });
            });
        }
      });

    this.loadingService.hide(processId);
  }

  formatMemberName(firstName:string,lastName:string){
    //if at least one of the name is not null, return the name
    let trimmedFirstName = firstName ? firstName.trim() : firstName;
    let trimmedLastName = lastName ? lastName.trim() : lastName;
  
    if (!trimmedFirstName && !trimmedLastName) {
      return ""
    }
  
    if (!trimmedFirstName) {
      return trimmedLastName;
    }
  
    if (!trimmedLastName) {
      return trimmedFirstName;
    }
  
    return `${trimmedFirstName} ${trimmedLastName}`;
  }
}
