import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { DialogBaseComponent } from 'src/app/components/common/dialog/dialog-base/dialog-base.component';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { ReportOptions } from '../reportOptions';

@Component({
  selector: 'app-report-options-popup',
  templateUrl: './report-options-popup.component.html',
  styleUrls: ['./report-options-popup.component.scss']
})
export class ReportOptionsPopupComponent extends DialogBaseComponent implements OnInit {

  public data         : ReportOptions;            // ReportOptions object which includes individual option fields. 
  public popupTitle   : string;                   // Option popup title
  isDirty: boolean = false;

  constructor(
     dialogSerivce           : DialogService,
     private ref1            : ChangeDetectorRef,
     private translateHandler: TranslateHandler
    ) {
    super(dialogSerivce, ref1);
  }

  ngOnInit(): void { 
    this.configTitle();
  }

  // handle the complete action
  save(){
    let options = new Map([]);
    for(let field of this.data.fieldOptions){
      options.set(field.fieldName, field.value);
    }
    //options ==> ReportOptions
    this.dialogSerivce.complete({data :options});
  }
  
  cancel(){
    this.dialogSerivce.complete({data : null});
  }

  // handle the combo box selection event
  onChange(event, optionId){
    this.data.fieldOptions.find(x=>x.id == optionId).value =event.target.value;
    this.isDirty = true;
  }

  // configure the option popup title [ Options for + {report name} ]
  configTitle(){
    let reportNameTranslation = this.translateHandler.translate(this.data.reportName)
    this.popupTitle           = this.translateHandler.translate("reportOptions.lbl_options_popup_title", [reportNameTranslation]);
  }

}
