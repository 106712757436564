import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FactsheetComponent } from './factsheet/factsheet.component';
import { ReportengineModule } from '../../reportengine/reportengine.module';
@NgModule({
  declarations: [FactsheetComponent],
  imports: [
    CommonModule,
    ReportengineModule
  ],
  entryComponents:[FactsheetComponent]
})
export class FactSheetModule { }