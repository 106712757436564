<div class="bookreport-container">
    <div class="flipbook-viewport" id="flipbook-viewport">
        <div id="flipbook-outer">
            <app-flipbookcontainer #bookContainer [bookReport]="bookReport" [bookReportConfig]="bookReportConfig"
                [hidden]="!isBookLoaded" (onFlipBookUILoaded)="onFlipBookUILoaded($event)"
                (onPageFlipped)="onPageFlipped($event)">
            </app-flipbookcontainer>
        </div>
    </div>
    <div class="bookreport-toolbar-container">
        <div class="bookreport-toolbar">
            <button [disabled]="!isRegenerateEnabled"
                [ngClass]="isRegenerateEnabled? 'tb-btn-enable' : 'tb-btn-disable'" class="tb-btn"
                (click)="regenerate()"><img src="/assets/images/icons/bookreport-regenerate.svg" alt="">
                    <span class="toolTip">{{translateText("lbl_regenerate")}}</span>
            </button>
            <button [disabled]="!isZoomInEnabled" [ngClass]="isZoomInEnabled? 'tb-btn-enable' : 'tb-btn-disable'"
                class="tb-btn" (click)="this.flipBook.zoomIn(); this.enableZoomOut()"><img alt="Zoom In"
                    src="/assets/images/icons/zoom-in.svg">
                    <span class="toolTip">{{translateText("lbl_zoomin")}}</span>
            </button>
            <button [disabled]="!isZoomOutEnabled" [ngClass]="isZoomOutEnabled? 'tb-btn-enable' : 'tb-btn-disable'"
                class="tb-btn" (click)="this.flipBook.zoomOut()"><img alt="Zoom out"
                    src="/assets/images/icons/zoom-out.svg">
                    <span class="toolTip">{{translateText("lbl_zoomout")}}</span>
            </button>
            <button [disabled]="!isFirstContentPageEnabled"
                [ngClass]="isFirstContentPageEnabled? 'tb-btn-enable' : 'tb-btn-disable'" class="tb-btn"
                (click)="this.flipBook.flipFirstContentPage()"><img
                    src="/assets/images/icons/bookreport-flipFirstPage.svg" alt="">
                    <span class="toolTip">{{translateText("lbl_flip_first_page")}}</span>
            </button>
            <button [disabled]="!isBackwardEnabled" [ngClass]="isBackwardEnabled? 'tb-btn-enable' : 'tb-btn-disable'"
                class="tb-btn" (click)="this.flipBook.flipBackward()"><img
                    src="/assets/images/icons/bookreport-backward.svg" alt="">
                    <span class="toolTip">{{translateText("lbl_flip_backword")}}</span>
            </button>
            <button [disabled]="!isForwardEnabled" [ngClass]="isForwardEnabled? 'tb-btn-enable' : 'tb-btn-disable'"
                class="tb-btn" (click)="this.flipBook.flipForward()"><img
                    src="/assets/images/icons/bookreport-forward.svg" alt="">
                    <span class="toolTip">{{translateText("lbl_flip_forward")}}</span>
            </button>
            <button [disabled]="!isLastContentPageEnabled"
                [ngClass]="isLastContentPageEnabled? 'tb-btn-enable' : 'tb-btn-disable'" class="tb-btn"
                (click)="this.flipBook.flipLastContentPage()"><img
                    src="/assets/images/icons/bookreport-flipLastPage.svg" alt="">
                    <span class="toolTip">{{translateText("lbl_flip_last_page")}}</span>
            </button>
            <button class="tb-btn tb-btn-enable" (click)="publishReport()">
                <img src="/assets/images/icons/download.svg" alt="">
                <span class="toolTip">{{translateText("lbl_publish")}}</span>
            </button>
            <button [disabled]="!isEditEnabled" [ngClass]="isEditEnabled? 'tb-btn-enable' : 'tb-btn-disable'"
                class="tb-btn" *ngIf="bookReportViewModel.reportStatus.isImageEditable" (click)="addImage()">
                <img src="/assets/images/icons/editImg.svg" alt="">
                <span class="toolTip">{{translateText("lbl_edit_image")}}</span>
            </button>
        </div>
    </div>
</div>