import { Component, OnInit, ElementRef  } from '@angular/core';
import { BaseComponent } from '../../BaseReport/base/base.component';
import { LoadingService } from "src/app/services/UI/loading.service";
import { TranslateHandler } from "src/app/common/helpers/translate-handler";
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { MessageDialogService } from 'src/app/components/common/message-dialog/services/message-dialog.service';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { FamilyProjectApiService } from 'src/app/services/API/family-project-api.service';
import { GuidGenerator } from "src/app/common/helpers/guid-generator";
import { ProjectRefService } from 'src/app/services/project-ref.service';
import { CirclePosition } from './circle-positions'
import { NotifierEvents } from 'src/app/common/enums/enums';
import { ReportOptions } from '../../common/reportOptions/reportOptions';
import { ReportFieldOption } from '../../common/reportOptions/reportFieldOption';


@Component({
  selector: 'app-factsheet',
  templateUrl: './factsheet.component.html',
  styleUrls: ['./factsheet.component.scss']
})
export class FactsheetComponent extends BaseComponent implements OnInit {

  expectedUpdates = [NotifierEvents.RootMemberChanged];
  reportOptions;   
  defaultHeight = 0; 
  public totalFamilyMembers: number;
  public mainCircleText: string;
  public circlePositions: CirclePosition[] = [];
  public secondaryCircleData = [
    'fact_sheet.lbl_second_circle_gender_text' ,
    'fact_sheet.lbl_second_circle_age_text' ,
    'fact_sheet.lbl_second_circle_relationship_status_text',
    'fact_sheet.lbl_second_circle_marriage_age_text',
    'fact_sheet.lbl_second_circle_living_vs_dead_text',
    'fact_sheet.lbl_second_circle_lifespan_text',
  ];
  public secondaryCircleCount = this.secondaryCircleData.length;

  constructor(
    private loadingService            : LoadingService,
    protected factSheettHostElement : ElementRef,
    protected translateHandler        : TranslateHandler,
    protected individualApiService    : IndividualApiService,
    protected messageDialogService    : MessageDialogService,
    protected notifierService         : NotifierV2Service,
    protected dialogService           : DialogService,
    protected familyProjectService : FamilyProjectApiService,
    private projectRefService: ProjectRefService
    ) {
    super(factSheettHostElement, dialogService, notifierService,translateHandler, messageDialogService,individualApiService); 
    }

    ngOnInit(): void {
      if (this.config) {
        this.chartBasics = {
          name: this.config.name,
          assetUrl: this.commonUrl,
        };
        this.initReport();
      }
    }

    notify() {
      this.initReport();
    }

    initReport(){
      this.rootMember = this.notifierService.getCurrentRootMember();
      this.config.options.title = this.translateHandler.translate("fact_sheet.lbl_title", [this.rootMember.DisplayName]);
      this.mainCircleText = this.translateHandler.translate("fact_sheet.lbl_main_circle_text")
      this.initReportOptions(); 
      this.initReportData();
    }

    initReportOptions(){
      this.reportOptions            = new ReportOptions();
      this.reportOptions.reportName = "fact_sheet.lbl_name"
      this.reportOptions.fieldOptions.push(new ReportFieldOption("title" ,"title", "input", [], this.config.options.title))
    }

    initReportData(customTitle = null){
      let processId = GuidGenerator.generate();
      this.loadingService.show(processId);
      var familyProjectId = this.projectRefService.getCurrentProject()?.familyProjectId;
      this.familyProjectService.getFamilyProjectStats(familyProjectId).
      subscribe(res => {
        this.totalFamilyMembers = res.data.people;
        this.calculateCirclePositions();
        this.showReport = true  
        this.reportZoomToExtend()  
        customTitle == null ? this.translateHandler.translate("fact_sheet.lbl_title",[this.rootMember.DisplayName]) : customTitle;  
      }).add(() => {
        this.loadingService.hide(processId);
      });
    }

    calculateCirclePositions() {
      const mainCircleRadius =  (this.config.base.mainCircle.height)/2; 
      const secondaryCircleRadius = (this.config.base.secondaryCircle.height)/2; 
      const circleDistance = mainCircleRadius + secondaryCircleRadius + 100; //100 was added to increase the distance between main and secondary circles
      this.circlePositions = []; 
      const mainCircleCenterX = (this.config.base.mainCircle.x)+(this.config.base.mainCircle.height/2)
    
      for (let i = 0; i < this.secondaryCircleCount; i++) {
        const angle = (2 * Math.PI * i) / this.secondaryCircleCount; // Distribute evenly around the main circle
    
        // Calculate the center x,y positions of a secondary circle based on the angle and distance from the center
        const cx = mainCircleCenterX + circleDistance * Math.cos(angle);
        const cy = mainCircleCenterX + circleDistance * Math.sin(angle) - (this.config.base.mainCircle.offset); 
    
        // Calculate the start position for the line on the edge of the main circle
        const lineStartX = mainCircleCenterX + mainCircleRadius * Math.cos(angle);
        const lineStartY = mainCircleCenterX + mainCircleRadius * Math.sin(angle) - (this.config.base.mainCircle.offset); 
    
        // Calculate the angle for the line (same as angle used for the secondary circle positioning)
        const lineAngle = (angle * (180 / Math.PI)+90); // Convert from radians to degrees

        const labelParts = this.translateHandler.translate(this.secondaryCircleData[i]).split(' ');

        this.circlePositions.push({
          cx,
          cy,
          label: labelParts, 
          lineStartX,
          lineStartY,
          lineAngle
        });
      }
    }

    onOptionsValueChanged(changedData:ReportOptions){
      this.mapConfig(changedData);
      this.initReportData(this.config.options.title);
    }
}
