export default class BookReportViewModel {
    lang: string;
    name: string;
    reportParams: string;
    reportStatus: any;
    constructor(name?: string, lang?: string,reportParams?:string,reportStatus?:any) {
        this.lang = lang;
        this.name = name;
        this.reportParams = reportParams;
        this.reportStatus = reportStatus || { type: 1, url: '' };
    }
}