import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Multimedia } from 'src/app/common/models/multimedia/multimedia';
import { PlaceLabelHelper } from 'src/app/common/helpers/place/place-label-helper';
import { PlaceAPIService } from 'src/app/services/API/place-api.service';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { PlaceAddressFormatter } from 'src/app/common/helpers/place/place-address-formatter';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { DropMenuAction, ReferenceType, MultimediaTypes, EditorMode, PopupViewMode } from 'src/app/common/enums/enums';
import { PlaceComponentModel } from '../models/place-component-model';
import { MessageDialogService } from '../../message-dialog/services/message-dialog.service';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { EventViewBase } from '../../events/models/event-view-base';
import { EventApiService } from 'src/app/services/API/event-api.service';
import { MapboxService } from "src/app/services/mapbox.service";
import { GeocodeApiService } from "src/app/services/API/geocode-api.service";
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GeocodeRequestModel } from 'src/app/common/models/geocode/geocode-request-model';
import { GeocodeResponseModel } from 'src/app/common/models/geocode/geocode-response-model';

@Component({
  selector: 'app-places-editor-temp',
  templateUrl: './places-editor-temp.component.html',
  styleUrls: ['./places-editor-temp.component.scss']
})
export class PlacesEditorTempComponent implements OnInit {
  
  constructor(private placeApiService           : PlaceAPIService,
              private loadingService            : LoadingService,
              private messageDialogService      : MessageDialogService,
              private translateHandler          : TranslateHandler,
              private dialogService             : DialogService,
              private eventApiService           : EventApiService,
              private mapboxService             : MapboxService,
              private geocodeApiService         : GeocodeApiService,
              private sanitizer                 : DomSanitizer
              ) 
  {
  }

  @Input() data;
  @Output() onCompleted = new EventEmitter<any>();
  googleMapUrl: SafeResourceUrl;
  placeModel: PlaceComponentModel;
  geocodeRequestModel:  GeocodeRequestModel;
  eventConfig:any;
  placeTypeList: any;
  selectedPlaceType: any;
  defaultCarouselImage: string = "assets/place/Place_default.png"
  public isAddButtonActive: boolean = false;
  public isValidLat: boolean = true;
  public isValidLng: boolean = true;
  public showGeocodeGenerateError: boolean = false;
  public geocodeAddress: string;
  public showAddressNotifier: boolean = false;
  public isInvalidLocation = true;
  public isDirty : boolean = false;


  inImages :  Array<Multimedia> = [];
  eventList: EventViewBase[];
  
  ngOnInit(): void {

    this.placeModel = new PlaceComponentModel();
    this.placeTypeList = PlaceLabelHelper.placeTypes;
    this.selectedPlaceType = PlaceLabelHelper.placeTypes[0];

    this.geocodeRequestModel =  new GeocodeRequestModel();
 
    this.eventConfig = {
      message : this.translateHandler.translate('err_no_event_for_resource', [this.translateHandler.translate('lbl_place').toLowerCase()]),
      referenceType : ReferenceType.Place
    }

    // != used because of type any
    if (this.data.reference.Id > 0 && this.data.reference.rawData != "") {
      // When user press edit scenario
      // Get Place API
      let processId = GuidGenerator.generate();
      this.loadingService.show(processId);
      this.placeApiService.getPlaceById(this.data.reference.Id).subscribe((response: ResponseModel<PlaceComponentModel>) => {      
        this.placeModel = response.data; 
        // (0,0) used as the default value by the backend
        // To avoid memeber(user) confution, If geocode are (0,0) frontend discard that value.
        if(this.placeModel.lat == 0 && this.placeModel.lng == 0){
          this.placeModel.lat = null;
          this.placeModel.lng = null;
        }
        if(this.placeModel.detail != null && this.placeModel.detail != "")
        {
          this.isAddButtonActive = true;
        } 
        if(this.placeModel.lat != null && this.placeModel.lng != null)
        {
          this.toggleOpenMapButton();
        } 
        this.selectedPlaceType = PlaceLabelHelper.placeTypes.find(t=>t.id == Number(this.placeModel.type)) ?? PlaceLabelHelper.placeTypes[0];
        this.getEventForPlace();
      }, (err) =>{
        throw new CustomError("DateHelper => FormatDate() : " + err, 911, false);
      }).add(()=>{     
        this.loadingService.hide(processId);
      });

    }   else if (this.data.reference.Id == 0) {     
      // When user enter a newly typed place scenario
      this.placeModel = PlaceAddressFormatter.addressSpliter(this.data?.reference?.rawData) as PlaceComponentModel;
      this.placeModel.id = this.data.reference.Id;
    }else{
      // When switching the tab from gallery to edit tab scenario
      this.placeModel.id = 0;
    }
  }

  /**
   * Checks whether user inputs are valid or not.
   * Here check only required field and geocodes only.(address, lat and lng)
   */
  validateForm(): void { 
    this.showGeocodeGenerateError = false;
    let isValidAddress = !!this.placeModel.detail;
    this.isAddButtonActive = isValidAddress && this.validateGeocode();
    this.toggleOpenMapButton();
    if(this.isAddButtonActive){
      this.isDirty = true;
    }
  }

  /**
    * Validates the latitude and longitude values.
    * Returns true if both latitude and longitude are valid within the specified ranges, or if both fields are empty.
    * Otherwise, returns false and sets corresponding validation flags.
  */
  validateGeocode(): boolean {
    const {lat, lng } = this.placeModel;
    
    this.isValidLat = isNaN(lat) || (lat >= -90 && lat <= 90);
    this.isValidLng = isNaN(lng) || (lng >= -180 && lng <= 180); 

    this.isValidLng = !isNaN(lat) && (isNaN(lng) || lng === null) ? false : this.isValidLng;
    this.isValidLat = !isNaN(lng) && (isNaN(lat) || lat === null) ? false : this.isValidLat;
  
    this.isValidLat = lat === null && lng === null ? true : this.isValidLat;
    this.isValidLng = lat === null && lng === null ? true : this.isValidLng;

    this.showAddressNotifier = (this.isValidLat || this.isValidLng) ? false :null;
    return (this.isValidLat && this.isValidLng);
  }

  /**
   * This method is responsible for getting and setting the geocode for a given address.
   * Calls the geocodeApiService.get() method to retrieve the geocode from the database.
   * Calls the mapboxService.getGeocode() method to retrieve the geocode from the Mapbox API.
   * Calls the createGeocode() method with the chosen response and the original address to create the geocode.
  **/
  processGeocodeData(): void {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    let address = this.getFullAddress();
    let encodedAddress = encodeURIComponent(address);
    let response;
  
    // Get geocode data from backend 
    let dbResponse$ = this.geocodeApiService.get(encodedAddress).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          return of(null);
        }
      })
    );

    // Get geocode data from mapboxAPI
    let apiResponse$ = this.mapboxService.getGeocode(encodedAddress);
    
    try {
      forkJoin([dbResponse$, apiResponse$]).subscribe(([dbResponse, apiResponse]) => {
        if (dbResponse !== null) {
          response = dbResponse;
        } else if (apiResponse.data.address != null) {
          response = apiResponse;
          this.createGeocode(response, address);
        }
        this.displayGeocodeData(response);
      }, (error) => {
        console.error(error);
      });
    } catch (error) {  
        console.error(error);
    } finally {
      this.loadingService.hide(processId);
    }
  }

  /**
   * Create geocode data in local db
   * data (GeocodeResponseModel)
   * **/
  createGeocode(response: GeocodeResponseModel, address: string): void {
    if(response.data != null) {
      const geocodeRequestModel: GeocodeRequestModel = {
        address: address,
        geocodeResponse: JSON.stringify(response.data.geocodeApiResponse),
        type: environment.GEOCODE.responseType
    };
    this.geocodeApiService.create(geocodeRequestModel);
    }
  }

  displayGeocodeData(response: GeocodeResponseModel): void {  
    let title             = this.translateHandler.translate("place.geocode.lbl_multiple_places");
    let info              = this.translateHandler.translate("place.geocode.cnf_apply_lnt_lng");

    if (response === null || response === undefined) {
      this.setGeocodeView(null, null, null, false, true);
    }
    if (response.data.isMultiplePlaces) {
        this.messageDialogService.openInfo(title, info, response.data.address).subscribe((res: boolean) => {
        if (res) {
          this.setGeocodeView(response.data.lat, response.data.lng, response.data.address, true, false);
        }
    });
    }
  }

  // When address is changed remove latitude and longitude
  addressChanged(isRequiredField: boolean = false): void {
    this.isDirty = true;
    if(this.placeModel.lat != null || this.placeModel.lng !=null){
      this.resetInputFieldsAndMessages();
      this.toggleOpenMapButton();
    }
    if (isRequiredField) {
      this.validateForm();
    }
  }

  setGeocodeView(lat:number, lng:number, address:string, showAddress?: boolean, showGeocodeError?: boolean): void {
      this.placeModel.lat           = lat;
      this.placeModel.lng           = lng;
      this.validateForm();  
      this.geocodeAddress           = address;
      this.showAddressNotifier      = showAddress;
      this.showGeocodeGenerateError = showGeocodeError;
  }

  public toggleOpenMapButton():void{
    this.isInvalidLocation = this.placeModel.lat == null ||
                             this.placeModel.lng == null || 
                             !this.isValidLat || 
                             !this.isValidLng 
  }

  resetInputFieldsAndMessages(): void {
    this.placeModel.lat           = null;
    this.placeModel.lng           = null;
    this.geocodeAddress           = null;
    this.showAddressNotifier      = false;
    this.showGeocodeGenerateError = false;
    this.isValidLat               = true;
    this.isValidLng               = true;
  }
 
  // Opens Google Map with the specified location detail in a new browser tab.
  openGoogleMap(isOpenAddress: boolean): void {
    let mapUrl;
    const { lat, lng } = this.placeModel;
    let address = this.getFullAddress();
    let apiKey = environment.GEOCODE.googleAccessToken;
    let title = this.translateHandler.translate("place.geocode.lbl_moving_to_google_map");
    let getLocationMapInfo = this.translateHandler.translate("place.geocode.lbl_moving_to_get_geocodes");
    let checkLocationMapInfo  = this.translateHandler.translate("place.geocode.lbl_moving_to_check_location");
    let selectedPlaceType = this.selectedPlaceType ? this.selectedPlaceType.description : ''
    let addressType       = this.translateHandler.translate(selectedPlaceType);
    
    if(isOpenAddress)
    {
      mapUrl = `/assets/map/googleMap.html?address=${encodeURIComponent(address)}&key=${apiKey}&addressType=${encodeURIComponent(addressType)}`;
      this.messageDialogService.openMessage(title, getLocationMapInfo);
    } else {
      mapUrl = `/assets/map/googleMap.html?lat=${lat}&lng=${lng}&key=${apiKey}&mapType=${("checkLocation")}`;
      this.messageDialogService.openMessage(title, checkLocationMapInfo);
    }
  
    setTimeout(() => {
      this.messageDialogService.close();
      this.googleMapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(mapUrl);
      window.open(mapUrl, '_blank');
    }, environment.PLACE.openMapDelayTimeOut); // Introduce a slight delay before opening the map
  }
    
  // Retrieves latitude and longitude values from the clipboard text and updates the lat, lng fields in the placeModel.
  pasteGeocode(): void {
    navigator.clipboard.readText()
      .then(text => {
        const [lat, lng] = text.split(',').map(parseFloat);     
        if (!isNaN(lat) && !isNaN(lng)) {     
          this.updateGeocode(lat, lng);
          this.toggleOpenMapButton();
        }
      })
      .catch(error => {
        console.error(error);
      });
  }
  
  
  private updateGeocode(lat: number, lng: number): void {
    this.placeModel.lat = lat;
    this.placeModel.lng = lng;
    this.isAddButtonActive = this.validateGeocode();
    if(this.isAddButtonActive){
      this.isDirty = true;
    }
  }
  
  addPlace() {
    this.placeModel.type = this.selectedPlaceType.id;
    this.placeModel.rawData = this.getFullAddress();
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    if (this.placeModel.id == 0) {
      // Create Place API
      this.placeApiService.createPlace(this.placeModel).subscribe((response: ResponseModel<PlaceComponentModel>) => {
        this.loadingService.hide(processId);

        this.placeModel = response.data;
        this.completeAction({ data: this.placeModel });
      }, (err)=>{
        throw new CustomError("DateHelper => FormatDate() : " + err, 911, false);
      }).add(()=>{     
        this.loadingService.hide(processId);
      });

    } else {
      // Update PLace API
      this.placeApiService.updatePlace(this.placeModel).subscribe((response: any) => {
        console.log(response.data);
        this.completeAction({ data: this.placeModel });
      }, (err) => {
        throw new CustomError("DateHelper => FormatDate() : " + err, 911, false);
      }).add(()=>{     
        this.loadingService.hide(processId);
      });
    }

  }

  completeAction(obj : any){
  // Avoid closing of popup when viewing list
  if(this.data.options.isSwitchToParent){
    this.onCompleted.next(obj);
    this.onCompleted.unsubscribe();
    return;
  }
  this.data.viewMode = PopupViewMode.GalleryView;
  this.data.reference = { Id: 0, rawData: "" }
  }

  cancel(){
    this.completeAction({});
  }

  getFullAddress(){
    return PlaceAddressFormatter.getFullAddress(this.placeModel) as string;
  }   

  carouselActionClicked($event) {
    let selectedMultimedia = $event.reference;
    let action = $event.action;

    if (action == DropMenuAction.Edit) {
      // Set view mode as 1 (Gallery View)
      this.addImage(selectedMultimedia.id, 2);
    }
    if (action == DropMenuAction.Unlink) {
      let unlinkMessage = this.translateHandler.translate("cnf_unlink_place_confirmation_message");
      this.messageDialogService.openUnlinkfirmation(unlinkMessage).subscribe((res: boolean) => {
        if (res) {
          this.unlinkImage(selectedMultimedia);
        }
      });
    }
    if (action == DropMenuAction.SetAsPrimary) {
      this.setImageAsPrimary(selectedMultimedia);
    }

    if (action == DropMenuAction.Upload) {
      this.addImage(0, 2);
    }

    if (action == DropMenuAction.Gallery) {
      this.addImage(0, 1);
    }
  }

  setImageAsPrimary(selectedMultimedia: Multimedia) {
    if (selectedMultimedia != null) {
      if (!selectedMultimedia.isPrimary) {
        this.placeModel.multimedias.map(m => m.isPrimary = m.id === selectedMultimedia.id);
      }
    }
  }

   //Set next image when primary image unlinked
   setNextImageAsPrimary(imageIndex: number) {  
    let isPrimaryImage = this.placeModel.multimedias?.find(t => t.isPrimary == true) ?? null;
    if (!isPrimaryImage && this.placeModel.multimedias?.length > 0) {
      if(this.placeModel.multimedias[imageIndex > 0 ? (imageIndex - 1) : 0].isNonProfile){
        this.placeModel.multimedias.find(t=>t.mediaType == MultimediaTypes.PHOTO && !t.isNonProfile).isPrimary = true;
      }else{
        this.placeModel.multimedias[imageIndex > 0 ? (imageIndex - 1) : 0].isPrimary = true;
      }
    }      
  }

  unlinkImage(selectedMultimedia: Multimedia) {
    if (selectedMultimedia != null) {
      let index = this.placeModel.multimedias.findIndex(i => i.id === selectedMultimedia.id);
      this.placeModel.multimedias.splice(index, 1);
      this.setNextImageAsPrimary(index);
    }
  }
  // Open multimedia 
  addImage(mediaId: any , viewMode : any) {  
    this.dialogService.open("Multimedia",
      {
        // Todo bind id and reference
        // Set id to 0 if StandAlone
        id: this.placeModel.multimedias?.find(t => t.isPrimary)?.id ?? 0,
        reference: {
          id: this.placeModel.id, // Place ID
          type: ReferenceType.Place // Place type
        },
        title: this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_media"),this.translateHandler.translate("lbl_place")]), // media for place for + <name>        
        mediaType: [MultimediaTypes.PHOTO],
        viewMode: viewMode,
        editMediaId: mediaId, // MediaId which supposes to be edited
        isStandAlone: false
      }
    )
      .subscribe(response => {
        if (response.data) {
          response.data.deleteMedias.forEach(element => {
            let index = this.placeModel.multimedias.findIndex(i => i.id === element.id);
            if (index >= 0) {
              this.placeModel.multimedias.splice(index, 1);
              this.setNextImageAsPrimary(index);
            }
          });

          if (response.data.newMedia) {
            response.data.newMedia.isNewlyAdded = true;          
            let mediaIndex = this.placeModel.multimedias?.findIndex(t => t.id == response.data.newMedia.id) ?? -1;
            !this.placeModel.multimedias? this.placeModel.multimedias = new Array<Multimedia>() : null;
            if (mediaIndex < 0)
              this.placeModel.multimedias.push(response.data.newMedia);
            else {              
              this.placeModel.multimedias[mediaIndex] = response.data.newMedia;             
            }
            // When adding new image to image carsoul , set this as primary
            this.placeModel.multimedias.map(m => m.isPrimary = m.id === response.data.newMedia.id);
          }

        }
      })
  }
  
  getEventForPlace(){
    this.eventApiService.getEventForPlace(this.data.reference.Id).subscribe((response: ResponseModel<EventViewBase[]>) => {
      this.eventList = response.data;
      // this.data.editorMode = 3;
     }, (err) =>{
      throw new CustomError("PlacesEditorTempComponent => getEventForPlace() : " + err, 911, false);
    }).add(()=>{  
    });
  }

  onModelChange() {
    this.isDirty = true; 
}
}
