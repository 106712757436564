import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopupOptions } from 'src/app/common/helpers/menulist/popup-options';
import { DialogBaseComponent } from 'src/app/components/common/dialog/dialog-base/dialog-base.component';
import { DialogService } from 'src/app/services/UI/dialog.service';

@Component({
  selector: 'app-variations-main',
  templateUrl: './variations-main.component.html',
  styleUrls: ['./variations-main.component.scss']
})
export class VariationsMainComponent extends DialogBaseComponent implements OnInit {

    public data: any;
    viewMode: number = 1;
    constructor(private ds: DialogService, private ref1: ChangeDetectorRef,) {
      super(ds, ref1);
    }
  
    ngOnInit(): void {
      this.getViewMode();
      let options = PopupOptions.options.find(t => t.mode == this.data.mode) || PopupOptions.options[0];
      this.data.options = options;
      this.data.options.primaryTabName = "menu.reports.lbl_reports";
    }
    
    getViewMode(): number {
      return this.data.viewMode;
    }
  
    changeViewMode(mode) {
      this.data.viewMode = mode;
      this.data.reference = { Id: 0, rawData: "" }
      if (mode == 1) {
        this.data.options.primaryTabName = "menu.reports.lbl_reports";
      } else {
        this.data.options.primaryTabName = "lbl_published_reports";
      }
    }
}
