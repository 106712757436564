<div class="ft-form">
  <div class="ft-form-row container">    
    <div *ngFor="let report of reports; let i = index" class="report-card">
      <label [for]="'report' + report.id" #imgDiv class="report-crop-img-container">
        <input type="radio" [id]="'report' + report.id" name="report" [value]="report.id" class="report-radio" (click)="onReportSelect(imgDiv, report.id)" />
        <div class="report-select"></div>
        <img class="report-img" [src]="report.image" [alt]="report.name | translate" />
        <div *ngIf="report.variations && report.variations.length > 0" class="themes-badge">
          <p>{{ report.variations.length }} {{ 'lbl_variations' | translate }}</p>
        </div>
        <div *ngIf="isNewReport(report.createdDate)" class="new-badge">
          <p>{{ 'lbl_menu_new' | translate }}</p>
        </div>
      </label>
      <p class="report-name">{{ report.name | translate }}</p>
      <p class="report-description">{{ report.description | translate }}</p>
    </div>
  </div>
  <div class="footer">   
    <button class="ft-btn" type="submit" form="ngFormPlace" [ngClass]="isAddButtonActive ? 'ft-btn-active' : 'ft-btn-inactive'" [disabled]="!isAddButtonActive" (click)="loadReport()">{{"lbl_select_menu" | translate}}</button>
    <button *ngIf="data.options.isCloseBtn" class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
  </div>
</div>