<div class="reports-dialog">
  <div class="dialog-title-container">
    <div class="dialog-title-text">{{ data.options.primaryTabName | translate }}</div>
  </div>
  <div class="tab-container app-dark-bg">            
          <div class="tabs">
              <a [class.active]="getViewMode() == 1" (click)="changeViewMode(1)">{{ "lbl_all_reports" | translate}}</a>
              <a [class.active]="getViewMode() == 2" (click)="changeViewMode(2)">{{"lbl_published_reports" | translate}}</a>
          </div>
          <div class="tab-forms app-light-bg">   
            <app-all-reports *ngIf="getViewMode() === 1" [data]="data"></app-all-reports>     
            <app-report-manager-main *ngIf="getViewMode() === 2"></app-report-manager-main>           
          </div>            
  </div>
</div>
