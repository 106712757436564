export enum RelationShipType {
    Father,
    Mother,
    Brother,
    Sister,
    Spouse,
    Son,
    Daughter
}

export enum ResourceType {
    Individual,
    Event,
    Source,
    Place,
    Family,
    Multimedia
}

export enum ReferenceType {
    Individual = 1,
    Family = 2,
    Event = 3,
    Place = 4,
    Source = 5,
    Multimedia = 6,
    None = 7,
}

export enum MultimediaPopupOpenMode {
    List,
    Edit,
    Add
}

export enum PopupOpenMode {
    List,
    Popup,
    Edit,
    Add
}

export enum MultimediaTypes {
    NONE = 0,
    PHOTO = 1,
    AUDIO = 2,
    VIDEO = 3,
    DOC = 4,
    UNKNOWN = 9
}

export enum PopupResponseEvents {
    Selected,
    DeSelected,
    Closed,
    Cancelled,
    Error,
    Edited
}

export enum CropFrameMode {
    Circle,
    Square
}

export enum GenealogyDateMode {
    full,
    partial,
    nomatch
}

export enum TooltipPosition {
    Top,
    Bottom,
    Left,
    Right
}

export enum DateTooltipPlace {
    MultimediaPopup,
    MemberEditor
}

export enum PlaceType {
    Address = "1",
    USA_Address = "2",
    Ecclesiatical = "3",
    DIS_Norway = "4",
    Geographically = "5",
    Old_Danish = "6",
    Official_Dansk = "7",
    Rode = "9",
    Technical = "10"
}

export enum ImageCarouselActionsTypes {
    SetAsPrimary,
    Unlink,
    Edit,
    Upload
}

export enum PlaceCreateType {
    UploadClick,
    ButtonClick
}

export enum EditorMode {
    IndividualEditor, // To be removed later
    TreeEditor,
    FamilyEditor
}

export enum ChildRelationshipType {
    na = 0, // Not Applicable
    bloodline = 1,
    foster = 2,
    adapted = 3,
    special = 4
}

// 2020.12.15 - DropmenuAction
export enum DropMenuType {
    Default = 0,
    Inverted = 1,
    Active = 2,
    Inactive = 3,
}

export enum DropMenuAction {
    Add,
    Edit,
    Delete,
    Upload,
    SetAsPrimary,
    Unlink,
    SetAsDefault,
    Gallery,
    Download,
    Info,
    ViewFamilyTree, //family projects manage options
    Import,
    Export,
    SetDefaultProject,
    ViewProjectDetails,
    DownloadExportFile,
    Colorize,
    ProjectSettings
}

export enum FamilyIndividuals {
    Parent = 1,
    Child = 2
}

export enum RootMemberRelation {
    Father,
    Mother,
    Brother,
    Sister,
    Wife,
    Husband,
    Son,
    Daughter,
    GrandSon,
    GrandDaughter,
    DaughterInLaw,
    SonInLaw,
    Self
}

export enum AliveStatus {
    Unknown = 0,
    Alive = 1,
    Dead = 2
}

export enum NotifierEvents {
//Use these events to notify actions between ComponentBase Inherits
    RootMemberChanged,
    RootMemberUpdated,
    RootMemberDeleted,

    DefaultFamilyChanged,
    RootFamilyChanged,
    RootFamilyUpdated,

    EditorModeChanged,
}
/**
 * If the given notifierEvent needs the data of its defaultFamily, retun true.
 * The namespace is used to create a method for the given enum NotifierEvents. this can be accessed by NotifierEvents.isDefaultFamilyRequired()
 */ 
export namespace NotifierEvents{
    export function isDefaultFamilyRequired(notifierEvent:NotifierEvents){
        if([NotifierEvents.RootMemberChanged,
            NotifierEvents.RootMemberUpdated,
            NotifierEvents.DefaultFamilyChanged,
            NotifierEvents.RootFamilyChanged,
            NotifierEvents.RootFamilyUpdated].includes(notifierEvent)){
                return true
        }
    }
}

export enum PopupViewMode {
    GalleryView = 1,
    EditorView = 2,
    ColorizerView = 3,
}

export enum TreeLevel {
    Main = 0,
    Four = 1,
    Five = 2,
    Six = 3,
}

export enum ImageOptions {
    IsColorized = 1,
    IsEnhanced = 2
}

export enum StripeMessageActions
{
    Download = 0,
    Dashboard = 1    
}

export enum featureType{
    fanchart = 1,
    sunchart = 2,
    familyTreeEditor = 3,
    colorizer = 4
}

export enum ReportType
{
    Fanchart  = 1,
    Sunchart  = 2,
    Factsheet = 3,
    Timelinereport  = 4,
    Mapreport       = 5,
    Fanchartjs  = 6,
    Artistictree = 7,
    Pedigree = 8,
    Timelinereportvine  = 9,
    Descendant = 10,
    PersonReport = 11,
    BirthdayGreeting = 12,
    EmptyMemberSheet = 13,
    AnniversaryGreeting = 14,
    GenealogyWorkbook = 15
}

export enum ErrorStatus{
    Available       = 1,
    Confirmation    = 2,
    NotAvailable    = 3
}

export enum ReportStatus {
    Pending         = 0,
    RequestCancel   = 1,
    Processing      = 2,
    Success         = 3,
    Failed          = 4,
    Cancelled       = 5
}

export enum ReportTemplate{
    ContentOnly = 1,
    WithHeader  = 2,
    None        = 3
}

export enum FamilyArchiveStatus{
    Pending         = 0,
    RequestCancel   = 1,
    Processing      = 2,
    Success         = 3,
    Failed          = 4,
    Cancelled       = 5
}

export enum FamilyArchiveActions{
    Import = 1,
    Export = 2
}

export enum PreferenceType{
    Global = "Global",
    Project = "Project",
    All = "All"
}

// Original source type
export enum OriginalSourceType{
    FT9     = "FT9",
    FT10    = "FT10",
    GEDCOM  = "GEDCOM",
}

export enum PageState{
    Nothing,
    Loading,
    Generating,
    Content,
    Error 
}

export enum PagePosition{
    First,
    Center,
    Last
}

export enum EventBoxSide {
    Left = 1,
    Right = 2
}

export enum EventType {
    Individual = 1,
    Family = 2,
}

export enum ReportPublishOptions{
    Title,
    Template,
    FileName
}
export enum ConnectorType {
    Line = "line",
    Rectangular = "rectangular"
}

export enum ChartTitlePosition {
    CENTER = "center",
    LEFT = "left",
    RIGHT = "right",
    MIDDLE = "middle",
    TOP = "top",
    BOTTOM = "bottom",
    START = "start",
    END = "end",
    HANGING = "hanging",
    BASELINE = "baseline",
}
